import React, { useEffect } from "react";
import moment from "moment";
// import { Image } from 'cloudinary-react'
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { setConfig, buildUrl } from "cloudinary-build-url";
import Card from "@mui/material/Card";

import { trackEvent } from "../../Utils/useGAEventTracker";

import "./styleCompo.css";
import { getUserById } from "../../Services/registerService";
import VerifiedBadge from "./VerifiedBadge";
import CardFlag from "./CardFlag";
import { Regions } from "../../Utils/regions";

setConfig({
  cloudName: "dw9gomx53",
});

const DisplayCards = ({ items, onHoverListing, selectedItem, users }) => {
  const { t } = useTranslation();

  const getVerified = (item) => {
    const listOwner = users.find((u) => u.id == item.list_owner_id);
    return listOwner && listOwner.verified;
  };

  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const calculateDate = (date) => {
    const postedDate = moment(parseInt(date));
    const nowDate = moment(Date.now());
    const diffInSec = nowDate.diff(postedDate, "seconds");
    const diffInMin = nowDate.diff(postedDate, "minutes");
    const diffInHour = nowDate.diff(postedDate, "hours");
    const diffInDay = nowDate.diff(postedDate, "days");
    const diffInMonth = nowDate.diff(postedDate, "months", true);
    const diffInYear = nowDate.diff(postedDate, "years");

    if (diffInSec < 60) return `${diffInSec} ${t("sec_ago")}`;
    if (diffInMin < 60) return `${diffInMin} ${t("min_ago")}`;
    if (diffInHour < 24) return `${diffInHour} ${t("hr_ago")}`;
    if (diffInDay < 30) return `${diffInDay} ${t("day_ago")}`;
    if (diffInMonth < 12)
      return `${Math.round(diffInMonth) < 1 ? 1 : Math.round(diffInMonth)} ${t(
        "mth_ago"
      )}`;

    return `${diffInYear} ${t("yr_ago")}`;
  };

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  return (
    <div className="cardsContainer">
      {items.map((item, index) => {
        const src = buildUrl(
          item.main_photo && item.main_photo.path && item.main_photo.path.url,
          {
            transformations: {
              resize: {
                type: "scale",
                width: 600,
                height: 700,
              },
            },
          }
        );

        return (
          <Card
            variant="outlined"
            data-aos={
              index / 2 === parseInt(index / 2, 10) ? "fade-right" : "fade-left"
            }
            key={`${item.id}`}
            className="displayListingCard"
            onMouseEnter={() => onHoverListing(item)}
            onMouseLeave={() => onHoverListing(null)}
          >
            {Regions().filter(
              (region) => region.country == item.country
            )[0] && (
                <CardFlag
                  src={
                    Regions().filter(
                      (region) => region.country == item.country
                    )[0].imageUrl
                  }
                />
              )}
            {getVerified(item) && <VerifiedBadge />}
            <Link
              onClick={() =>
                trackEvent(
                  "display list card click",
                  `/listing/${item.id}`,
                  "display card link"
                )
              }
              to={`/listing/${item.id}`}
              style={{
                width: "100%",
                height: "80%",
                outline: "0",
                padding: "0",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  outline: "0",
                  backgroundColor: "lightgray",
                }}
              >
                <img
                  crossOrigin="anonymous"
                  className="card-img-top"
                  src={src}
                  alt="Card image cap"
                />
              </div>
            </Link>
            <div className="card-body cardBodyContainerForDisplayCard">
              <div className="title_locationCon">
                <h6 className="card-title card-title1">
                  <b
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 1,
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {capitalize(item.title)}
                  </b>
                </h6>
                <h6
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    lineClamp: 1,
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                  }}
                  className="card-title card-title2"
                >
                  <b>{`${commaSeparateNumber(item.price)} ${item.currency}`}</b>
                </h6>
              </div>
              <div className="price_posted">
                <p className="card-text">
                  {t("displayCard_bedroom_num", {
                    bedrooms_nums:
                      item.listing_type !== "Land" ? t(item.bedrooms) : "",
                    bedrooms: item.listing_type !== "Land" ? t(`Bedrooms`) : "",
                    listing_type: t(item.listing_type),
                    city: item.city,
                  })}
                </p>
                <p className="card-text">
                  {t("display_Posted")}: {calculateDate(item.dateposted)}
                </p>
              </div>
            </div>
          </Card>
        );
      })}
      <ReactTooltip effect="solid" />
    </div>
  );
};

export default DisplayCards;
