import React from "react";
import "./manageTenants.css";
import { Chip } from "@mui/material";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const AddTenantInput = ({
  onChange,
  value,
  title,
  id,
  type = "text",
  placeholder,
  chip,
  listing_spaces,
  listing,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <label className="addTenantInputLabel" htmlFor={id}>
        {title}{" "}
        {chip && (
          <HtmlTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#035aa6",
                  }}
                  color="inherit"
                  variant="p"
                >
                  <span style={{ fontWeight: "bold" }}>{t("Available Quantity")}</span>
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {listing_spaces?.filter((l) => l.empty_space === true)
                      ?.length > 0
                      ? `
       - ${listing_spaces?.filter((l) => l.empty_space === true)?.length};`
                      : t(`The total amount of available quantity`, {
                        chip,
                        listing_type: t(listing?.listing_type)
                      })}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#035aa6",
                  }}
                  color="inherit"
                  variant="p"
                >
                  <span style={{ fontWeight: "bold" }}>
                    {listing_spaces?.filter((l) => l.empty_space === true)
                      ?.length > 0 &&
                      `${t("Available")} ${t(listing.listing_type)}${listing_spaces?.filter((l) => l.empty_space === true)
                        ?.length > 1
                        ? "s"
                        : ""
                      }`}
                  </span>
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {listing_spaces?.filter((l) => l.empty_space === true)
                      ?.length > 0 &&
                      `- #${listing_spaces
                        ?.filter((l) => l.empty_space === true)
                        .map((l) => l.space_name ? l.space_name : l.space_number)
                        .join(", ")}`}
                  </span>
                </Typography>
              </div>
            }
          >
            <Chip style={{ cursor: "pointer" }} label={chip} />
          </HtmlTooltip>

          // </Tooltip>
        )}
      </label>
      <input
        placeholder={placeholder}
        value={value}
        type={type}
        id={id}
        onChange={onChange}
        className="form-control addTenantInput"
        disabled={disabled}
      />
    </>
  );
};

export default AddTenantInput;
