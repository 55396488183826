import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { setConfig, buildUrl } from "cloudinary-build-url";

import TitlePriceLeaseSign from "./TitlePriceLeaseSign";
import "./leaseSignDetails.css";
import MoreAboutListingLease from "./MoreAboutListingLease";
import LeftArrow from "./LeftArrow";
import CloseDealButton from "./CloseDealButton";
import CloseFinalDeal from "./CloseFinalDeal";
import { getCurrentUser } from "../../Services/authService";
import LoadingSpinner from "../Applications/LoadingSpinner";
import ListingAnalytics from "../ManageTenantsDet/ListingAnalytics";
import { Alert, Avatar, CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EditLeaseStartMenu from "./EditLeaseStartMenu";
import { Image } from "cloudinary-react";
import SelectedDocumentModal from "../ManageTenantsDet/SelectedDocumentModal";
import { getUserById } from "../../Services/registerService";

setConfig({
  cloudName: "dw9gomx53",
});

const ListingInfo = ({
  listingAnalysis,
  listing,
  landlordDetails,
  dateLeaseStartAndExpiry,
  mySignLease,
  detailLeaseSign,
  monthsCover,
  onClickCloseDeal,
  loadingCloseDeal,
  requestToClose,
  closeDeal,
  cancelDate,
  onClickRejectCancellation,
  leaseMonthEnds,
  onClickUpdateLeaseEndDate,
  loadingEndDate,
  tenantSpace,
  availableSpace,
  onClickEdit,
  onChangeSD,
  valueSD,
  onsubmitEditStartDate,
  editStartingDateError,
  loadingEditStartDate,
}) => {
  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState({})

  const { t } = useTranslation();

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const src =
    listing &&
    listing.main_photo &&
    listing.main_photo.path &&
    buildUrl(listing && listing.main_photo && listing.main_photo.path.url, {
      transformations: {
        resize: {
          type: "scale",
          width: 600,
          height: 900,
        },
      },
    });

  const onClickImage = (src) => {
    setOpen(true);
    setSelectedDocument(src);
  }

  React.useEffect(() => {
    getOwner()
  }, [])
  const getOwner = async () => {
    if (getCurrentUser().id) {
      const { data: us } = await getUserById(getCurrentUser().id)
      setUser(us);
    }
  };

  return (
    <div className="leaseSignDetailListingInfoContainer">
      <SelectedDocumentModal
        open={open}
        handleClose={() => setOpen(false)}
        selectedDocument={selectedDocument}
        onPrev={(select) => {
          const index = detailLeaseSign.other_photos.indexOf(select);
          if (index === 0) {
            setSelectedDocument(detailLeaseSign.other_photos[detailLeaseSign.other_photos.length - 1])
          } else {
            setSelectedDocument(detailLeaseSign.other_photos[index - 1])
          }
        }}
        onNext={(select) => {
          const index = detailLeaseSign.other_photos.indexOf(select);
          if (index === detailLeaseSign.other_photos.length - 1) {
            setSelectedDocument(detailLeaseSign.other_photos[0])
          } else {
            setSelectedDocument(detailLeaseSign.other_photos[index + 1
            ])
          }
        }}
      />
      <h5 className="title">{t("Listing_Info")}</h5>
      <hr style={{ margin: "5px auto" }} />
      <div style={{ display: "flex", flexDirection: "row", margin: "5px 5px" }}>
        <TitlePriceLeaseSign
          verified={landlordDetails && landlordDetails.verified}
          title={_.toUpper(
            listing.title && listing.title.length > 25
              ? listing && listing.title.slice(0, 25) + "..."
              : listing && listing.title
          )}
          price={`${listing && listing.price}`}
          rentOrSell={listing && listing.rent_or_sell}
          listing={listing}
          tenantSpace={tenantSpace}
        />
      </div>

      <div className="infoDetailsContainerleaseSignDet">
        <div className="infoDetailsContainerleaseSignDetInnerCon">
          {listing && (
            <MoreAboutListingLease
              advancedPayment={`${listing.advance_price
                ? commaSeparateNumber(listing.advance_price)
                : ""
                } ${listing.currency}`}
            />
          )}

          <div className="landlordInfo">
            <div className="imageContainer">
              <Avatar
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "lightgray",
                }}
                alt="Remy Sharp"
                src={
                  landlordDetails &&
                  landlordDetails.picture &&
                  landlordDetails.picture.fileProfile.url
                }
              />
            </div>
            <div className="nameContainer">
              <h6>
                {capitalize(
                  landlordDetails.first_name
                    ? landlordDetails.first_name
                    : "loading"
                )}{" "}
                {capitalize(
                  landlordDetails.first_name
                    ? landlordDetails.last_name
                    : "loading"
                )}
              </h6>
            </div>
          </div>

          <div className="leaseTypeLeasingSignDet">
            <span className="title">{t("Lease_Type")}: </span>
            <span className="monthToMonthLeasingSignDet">
              {listing.fixed_agreement
                ? t(`-Fixed_Lease`)
                : listing.month_to_month_agreement
                  ? t(`-Month-to-Month_Lease`)
                  : listing.sold_agreement
                    ? t("-Sold_Lease")
                    : ""}
            </span>
            {listing.fixed_agreement && (
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="leasingEndLeasingDet"
              >
                {listing.agreement_after_fixed_agreement
                  .renew_fixed_agreement ? (
                  <>
                    {" "}
                    <span style={{ marginRight: "5px" }}>
                      <LeftArrow />
                    </span>{" "}
                    {t("-renew_fixed_lease")}{" "}
                  </>
                ) : listing.agreement_after_fixed_agreement
                  .continue_with_month_to_month ? (
                  <>
                    <span style={{ marginRight: "5px" }}>
                      <LeftArrow />
                    </span>
                    {t("-May_continue_to_lease")}
                  </>
                ) : listing.agreement_after_fixed_agreement.vacateP ? (
                  <>
                    <span style={{ marginRight: "5px" }}>
                      <LeftArrow />
                    </span>
                    {t("-Must_vacate_the_Premises")}
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          {detailLeaseSign?.other_photos?.length > 0 &&
            <>
              <p style={{ color: "GrayText", textAlign: "center", fontWeight: "bold", fontSize: "12px" }}>Other documents attached</p>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                <div className="fileInputMultipleImg">
                  {detailLeaseSign?.other_photos.map((src) => (
                    <div style={{ borderRadius: "5px" }} onClick={() => onClickImage(src)} key={src.public_id}>
                      {src.filePath && src.filePath.format === "pdf" ? (
                        <embed src={src.filePath.url} width="80px" height="80px" />
                      ) : (
                        <Image
                          style={{ border: "1px solid black", }}
                          crossOrigin="anonymous"
                          className="overlay-image animate__animated"
                          cloudName="dw9gomx53"
                          publicId={src.public_id}
                        />
                      )}
                    </div>
                  ))}
                </div>

              </div>
            </>
          }
          <hr className="mapSeparator" />
          <p style={{}}>
            <span style={{ font: "normal normal bold 14px/25px Merriweather" }}>
              {t(listing.listing_type)} # :
            </span>
            <span
              style={{
                marginLeft: "5px",
                font: "normal normal normal 14px/25px Merriweather",
              }}
            >
              {tenantSpace.space_name ? tenantSpace.space_name : tenantSpace.space_number}
            </span>
          </p>
          <p style={{}}>
            <span style={{ font: "normal normal bold 14px/25px Merriweather" }}>
              {t("Total # of")} {t(listing.listing_type)}(s) :
            </span>
            <span
              style={{
                marginLeft: "5px",
                font: "normal normal normal 14px/25px Merriweather",
              }}
            >
              {availableSpace.length}
            </span>
          </p>
          <hr className="mapSeparator" />
          <div className="leaseStartingAndEndingBox">
            <div className="startDateContainer">
              <h5>{t("Lease_Starting_Date")} </h5>
              <p>

                {dateLeaseStartAndExpiry().startDate}{" "}
              </p>
              {/* <p>
                <EditLeaseStartMenu
                  onChangeSD={onChangeSD}
                  valueSD={valueSD}
                  title="Edit Lease Starting Date *"
                  onsubmit={onsubmitEditStartDate}
                  editStartingDateError={editStartingDateError}
                  loadingEditStartDate={loadingEditStartDate}
                />
              </p> */}
            </div>
            <div
              className={
                leaseMonthEnds ? "startDateContainer1" : "startDateContainer"
              }
            >
              {leaseMonthEnds ? (
                <>
                  <Tooltip
                    title={
                      getCurrentUser() &&
                        getCurrentUser().post_rent_property !==
                        "I AM A PROPERTY OWNER"
                        ? `You have passed your lease ending date (${dateLeaseStartAndExpiry().endDate
                        }). If you wish to continue living in this property,
              request your landlord to extend your lease end date,
              so as to get full updates about your lease.`
                        : `This Tenant has passed his/her lease ending date (${dateLeaseStartAndExpiry().endDate
                        }). Please extend his/her lease end date,`
                    }
                  >
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <h5>{t("Lease_Ending_Date")} </h5>
                      <p>
                        {listing && listing.rent_or_sell === "Rental"
                          ? dateLeaseStartAndExpiry().endDate
                            ? dateLeaseStartAndExpiry().endDate
                            : t("Not_Specific_Date")
                          : t("owned")}
                      </p>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <h5>{t("Lease_Ending_Date")} </h5>
                  <p>
                    {listing && listing.rent_or_sell === "Rental"
                      ? dateLeaseStartAndExpiry().endDate
                        ? dateLeaseStartAndExpiry().endDate
                        : t("Not_Specific_Date")
                      : t("owned")}
                  </p>
                </>
              )}

              {/* {getCurrentUser() &&
                getCurrentUser().post_rent_property ===
                  "I AM A PROPERTY OWNER" && ( */}
              {/* {leaseMonthEnds &&
                getCurrentUser() &&
                getCurrentUser().post_rent_property ===
                "I AM A PROPERTY OWNER" && (
                  <Tooltip title="Update the lease end date of this tenant">
                    <button
                      style={{
                        height: "36px",
                        width: "36px",
                        borderRadius: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                      className="btn btn-light"
                      onClick={onClickUpdateLeaseEndDate}
                    >
                      <EditCalendarIcon style={{ fontSize: "22px" }} />
                    </button>
                  </Tooltip>
                )} */}
              <div style={{ marginLeft: "5px" }}>
                {loadingEndDate && (
                  <CircularProgress size={20} sx={{ color: "#035aa6" }} />
                )}
              </div>
            </div>

            <div className="startDateContainer">
              <h5>{t("Duration Spent")} </h5>
              <p>
                {Math.floor(monthsCover / 12) >= 1
                  ? `${Math.floor(monthsCover / 12)} ${monthsCover % 12}`
                  : monthsCover > 0
                    ? `${monthsCover} Months`
                    : "less than 1 Mth"}
              </p>
            </div>
            {cancelDate && closeDeal && (
              <div className="startDateContainer">
                <h5>{t("Lease Cancel Date")} </h5>
                <p>{cancelDate}</p>
              </div>
            )}
          </div>

          <hr className="mapSeparator" />

          <div className="ToTalPaidTotalOwedAmtBox">
            <div className="totalPaid">
              <h5>{t("TOTAL_AMOUNT_PAID")} </h5>
              <p>
                {mySignLease && mySignLease.total_amount_paid
                  ? commaSeparateNumber(
                    mySignLease &&
                    Number(mySignLease.total_amount_paid).toFixed(2)
                  )
                  : 0}{" "}
                {listing && listing.currency}
              </p>
            </div>

            <div
              className={
                Number(mySignLease.amount_owed) > 0 ? "totalPaid1" : "totalPaid"
              }
            >
              {Number(mySignLease.amount_owed) > 0 ? (
                <Tooltip
                  title={`${t(`You are owing the sum of`)} ${mySignLease?.amount_owed
                    ? `${mySignLease?.amount_owed}`.startsWith(
                      "-"
                    )
                      ? "0"
                      : commaSeparateNumber(
                        mySignLease &&
                        Number(mySignLease.amount_owed).toFixed(2)
                      )
                    : ""
                    } ${listing?.currency
                    }, ${t(`Please make payment to your landlord to avoid any legal action.`)}`}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h5>{t("TOTAL_AMOUNT_OWED")} </h5>
                    <p>
                      {Number(mySignLease && mySignLease.amount_owed) > 0
                        ? commaSeparateNumber(
                          mySignLease && mySignLease.amount_owed
                        )
                        : 0}{" "}
                      {listing && listing.currency}
                    </p>
                  </div>
                </Tooltip>
              ) : (
                <>
                  <h5>{t("TOTAL_AMOUNT_OWED")} </h5>
                  <p>
                    {Number(mySignLease && mySignLease.amount_owed) > 0
                      ? mySignLease && mySignLease.amount_owed
                      : 0}{" "}
                    {listing && listing.currency}
                  </p>
                </>
              )}
            </div>
            <div className="totalPaid">
              {listing.rent_or_sell === "Rental" ? (
                <>
                  <h5>{t("TOTAL_MONTHS_PAID")}</h5>
                  <p>
                    {Math.floor(
                      Number(mySignLease && mySignLease.total_amount_paid) /
                      Number(listing && listing.price) /
                      12
                    ) > 0 &&
                      `${Math.floor(
                        Number(mySignLease && mySignLease.total_amount_paid) /
                        Number(listing && listing.price) /
                        12
                      )} ${t("Year")}(s)`}{" "}
                    {(
                      (Number(mySignLease && mySignLease.total_amount_paid) /
                        Number(listing && listing.price)) %
                      12
                    ).toFixed(2)}{" "}
                    {t("Month")}(s)
                  </p>
                </>
              ) : (
                <>
                  <h5>{t("PAYMENT_STATUS")} :</h5>
                  <p>
                    {Number(mySignLease && mySignLease.total_amount_paid) >=
                      Number(listing.price)
                      ? t("Complete")
                      : "Part"}
                  </p>
                </>
              )}
            </div>
          </div>

          <hr className="mapSeparator" />

          <div
            className="infoButtonContainer"
            style={{ width: "100%", padding: "20px 10px" }}
          >
            {user.suspended || landlordDetails.suspended ?
              <Alert style={{ width: "96%", margin: '10px 2%' }} severity="warning">
                This account has been suspended, you can't close lease agreement.
              </Alert>
              :
              loadingCloseDeal ? (
                <LoadingSpinner />
              ) : closeDeal ? (
                <div
                  className="info info-danger"
                  style={{
                    font: "normal normal normal 16px/25px Merriweather",
                    background: "red",
                    padding: "5px",
                    color: "white",
                    opacity: "0.7",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  {t("This Agreement Have Been Closed In A Mutual Term By Landlord And Tenant")}
                </div>
              ) : mySignLease.tenant_close_deal &&
                !mySignLease.landlord_close_deal &&
                getCurrentUser().post_rent_property ==
                "LOOKING TO BUY/RENT PROPERTY" ? (
                <div
                  style={{
                    padding: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px red dashed",
                    width: "80%",
                    borderRadius: "10px",
                    font: "normal normal normal 16px/25px Merriweather",
                  }}
                >
                  <CloseFinalDeal />
                  <div>{t("Waiting For Landlord To Cancel Deal")}</div>
                </div>
              ) : !mySignLease.tenant_close_deal &&
                mySignLease.landlord_close_deal &&
                getCurrentUser().post_rent_property == "I AM A PROPERTY OWNER" ? (
                <div
                  style={{
                    padding: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px red dashed",
                    width: "80%",
                    borderRadius: "10px",
                    font: "normal normal normal 16px/25px Merriweather",
                  }}
                >
                  <CloseFinalDeal />
                  <div>{t("Waiting For Tenant To Cancel Deal")}</div>
                </div>
              ) : (
                <CloseDealButton
                  requestToClose={requestToClose}
                  onClickCloseDeal={onClickCloseDeal}
                  onClickRejectCancellation={onClickRejectCancellation}
                />
              )}
          </div>
        </div>

        <div className="pictureContaineLeaseSignDet">
          <img
            style={{
              objectFit: "cover",
              boxShadow: "3px 1px 3px 0.5px #969696 ",
            }}
            src={src}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingInfo;
