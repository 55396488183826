import cookies from "js-cookie";
import React from 'react'

const GoogleTranslate = (data) => {
  const [lang, setLang] = React.useState("en");

  React.useEffect(() => {
    try {
      const addGoogleTranslateScript = () => {
        const googleTranslateScript = document.createElement("script");
        googleTranslateScript.type = "text/javascript";
        googleTranslateScript.async = true;
        googleTranslateScript.src = `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;
        document.body.appendChild(googleTranslateScript);
        // const currentLanguageCode = cookies.get("i18next");
        window.googleTranslateElementInit = () => {
          new window.google.translate.TranslateElement(
            {
              pageLanguage: lang,
              autoDisplay: false,
              includedLanguages: "fr,es,en", // Add desired languages
              layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            },
            "google_translate_element"
          );
        };
      };

      addGoogleTranslateScript();

    } catch (error) {
      console.log(error)
    }
  }, [])

  return <div id="google_translate_element">
    {data}
  </div>;

}

export default GoogleTranslate
