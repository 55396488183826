import React, { useEffect, useState, useContext } from "react";
import _, { set } from "lodash";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import io from "socket.io-client";

import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import SidebarTenant from "./../components/AddListing/SideBarTenant";
import OpenButtonTenant from "./../components/AddListing/OpenButtonTenant";
import WelcomeUser from "./../components/AddListing/WelcomeUser";
import Chats from "./../components/AddListing/Chats";
import SearchPaymentLeaseSign from "../components/LeaseSignDetails/SearchPaymentLeaseSign";
import TableLeaseSignDet from "../components/LeaseSignDetails/TableLeaseSignDet";
import MakePaymentModal from "../components/LeaseSignDetails/MakePaymentModal";
import ListingInfo from "../components/LeaseSignDetails/ListingInfo";
import Meta from "./../Utils/Meta";
import UnreadMsgsContext from "./../unreadmessages/context";
import { getCurrentUser } from "./../Services/authService";
import {
  getASignLeaseById,
  getALeaseSignById,
  putLandlordRejectCancel,
  getStatusByApplicantsId,
} from "../Services/applicationStatus";
import {
  getLandlordsPaymentMethod,
  getUserById,
} from "./../Services/registerService";
import {
  clientGetAvailableSpaces,
  getListingsById,
} from "../Services/listings";
import { getAllUsersPaymentMethods } from "../Services/paymentOptions";
import {
  getUsersPaymentsByLeaseId,
  postAndDownloadPaymentService,
  getPaymentDetailsPayerById,
  getPaypalCredentials,
} from "../Services/payment";
import { userConfirmPassword } from "../Services/authService";
import { currencyExchange } from "./../Utils/currencyExchange";
import { Regions } from "../Utils/regions";
import { myDateToSort } from "../Utils/currentDate";
import { trackEvent } from "../Utils/useGAEventTracker";
import "../components/Leases/leases.css";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import { currencyCodes2 } from "../Utils/currencyCodes";
import { currencyListExchange } from "../Utils/currencyListExchange";
import CloseDeal from "../components/LeaseSignDetails/CloseDeal";
import CancelAgreementAlert from "../components/LeaseSignDetails/CancelAgreementAlert";
import DebtFree from "../components/Leases/DebtFree";
import OwingAnimation from "../components/Leases/OwingAnimation";
import AlertCustomise from "../components/Home/AlertCustomise";
import Meta1 from "../Utils/Meta1";

const LeaseSignDetails = ({ match, history }) => {
  const { t } = useTranslation();

  const [path, setPath] = useState("");
  const [close, setClose] = useState(true);
  const [loadingPayments, setLoadingPayments] = useState(false);

  const [mySignLease, setMySignLease] = useState({});
  const [detailLeaseSign, setDetailLeaseSign] = useState({});
  const [landlordPaymentMethod, setLandlordPayment] = useState({});
  const [landlordDetails, setLandlordDetails] = useState({});
  const [listing, setListing] = useState({});
  const [tenantPaymentMethods, setTenantPaymentMethods] = useState([]);

  const [underConstruction, setUnderConstruction] = useState(false);
  const [sortColumns, setSortColumns] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [accountBalance, setAccountBalance] = useState({});

  const [selectedPayment, setSelectedPayment] = useState("");
  const [amount, setAmount] = useState("");
  const [listCurrencyCode, setListCurrencyCode] = useState("");
  const [listingExchange, setListingExchange] = useState(1);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState("");

  const [currencyEuroExchangeRate, setCurrencyEuroExchangeRate] = useState();
  const [payments, setPayments] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });
  const [sender_currency, setSender_currency] = useState("");

  const [loadingSearchPayment, setLoadingSearchPayment] = useState(false);
  const [selectedPaymentToLandlord, setSelectedPaymentToLandlord] =
    useState("");
  const [selectedPaymentTable, setSelectedPaymentTable] = useState([]);

  const [paymentDetails, setPaymentDetails] = useState([]);

  const [loadingConversion, setLoadingConversion] = useState(false);

  const [closeDeal, setCloseDeal] = useState(false);
  const [loadingCloseDeal, setLoadingCloseDeal] = useState(false);

  const [requestToClose, setRequestToClose] = useState(false);

  const [rejectCancel, setRejectCancel] = useState(false);

  const [sdkReady, setSdkReady] = useState(false);

  const [socket, setSocket] = useState();

  const [paypalUSDListingConv, setPaypalUSDListingConv] = useState(1);

  const [paypalClientId, setPaypalClientId] = useState("");

  const [confirmPassword, setConfirmPassword] = useState(false);
  const [confirmPasswordData, setConfirmPasswordData] = useState("");
  const [chances, setChances] = useState(10);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmPasswordPay, setConfirmPasswordPay] = useState(false);

  const [numberOfMonths, setNumberOfMonths] = useState("1");
  const [amountPercent, setAmountPercent] = useState(1);

  const [availableSpace, setAvailableSpace] = useState([]);
  const [tenantSpace, setTenantSpace] = useState({});

  const { setMyLeasesApplicationsOwed, unreadMsgs } =
    useContext(UnreadMsgsContext);

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    localStorage.setItem("portalPath", "/portal/tenant/leases");
    localStorage && localStorage.removeItem(`payingWithStripe`);
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    getMyDatas();
    return () => {
      localStorage.setItem("portalPath", "");
    };
  }, []);

  const getMyDatas = async () => {
    try {
      const sockets = io();

      setSocket(sockets);
      setLoadingPayments(true);

      const { data: signLease } = await getASignLeaseById(match.params.id);
      if (!signLease.tenant_close_deal && signLease.landlord_close_deal) {
        setRequestToClose(true);
      } else {
        setRequestToClose(false);
      }
      setMySignLease(signLease);

      sockets.on(
        `${getCurrentUser() && getCurrentUser().id}tenant_cancelled_agreement`,
        (data) => {
          if (data.reject) {
            setRejectCancel(true);
            setInterval(() => {
              setRejectCancel(false);
            }, 15000);
          }
          setMySignLease(data.applSign);
          if (
            !data.applSign.tenant_close_deal &&
            data.applSign.landlord_close_deal
          ) {
            setRequestToClose(true);
          } else {
            setRequestToClose(false);
          }
          if (
            data.applSign.tenant_close_deal &&
            data.applSign.landlord_close_deal
          ) {
            setCloseDeal(true);
          } else {
            setCloseDeal(false);
          }
        }
      );

      if (signLease.tenant_close_deal && signLease.landlord_close_deal) {
        setCloseDeal(true);
      } else {
        setCloseDeal(false);
      }

      const { data: detailLease } = await getALeaseSignById(
        signLease.application_id
      );
      setDetailLeaseSign(detailLease);

      const { data: landlordPaymentMethod } = await getLandlordsPaymentMethod(
        signLease.list_owner_id
      );

      setLandlordPayment(landlordPaymentMethod);

      const { data: mylisting } = await getListingsById(signLease.listing_id);
      setListing(mylisting);
      setAmount(`${mylisting.price}`);

      const { data: myPaymentMethods } = await getAllUsersPaymentMethods();

      const sortedPayment = _.orderBy(
        myPaymentMethods,
        [sortColumns.path],
        [sortColumns.order]
      );
      setTenantPaymentMethods(sortedPayment);

      const listOwner = signLease
        ? await getUserById(signLease.list_owner_id)
        : "";
      setLandlordDetails(listOwner.data);

      const theCountry = Regions().filter(
        (ctry) => ctry.country === mylisting.country
      )[0];

      setListCurrencyCode(theCountry.currencyCode);

      const { data: myPaymentsForThisLease } = await getUsersPaymentsByLeaseId(
        detailLease.id
      );

      const sortedPayments = _.orderBy(
        myPaymentsForThisLease,
        [sortColumn.path],
        [sortColumn.order]
      );
      setPayments(sortedPayments);

      const { data: applStatusPaymentDetails } =
        await getPaymentDetailsPayerById(
          getCurrentUser() && getCurrentUser().id
        );

      setPaymentDetails(applStatusPaymentDetails);

      ///////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////
      ///collecting data to execute payment of debt
      const active = sortedPayment.filter((p) => p.active === true);

      if (active[0]) {
        const countryObj = currencyCodes2().filter(
          (x) => x.ctry === active[0].payment_credential.country
        );

        setSender_currency(
          active[0].payment_option == "MTN Mobile Money"
            ? countryObj[0].code
            : theCountry.currencyCode
        );
        const currencyTo = "EUR";
        currencyExchange(
          active[0].payment_option == "MTN Mobile Money"
            ? countryObj[0].code
            : theCountry.currencyCode,
          currencyTo,
          (exchange) => {
            console.log("exchange", exchange);
            setCurrencyEuroExchangeRate(exchange);
          },
          (err) => setError("Network Error, please Retry"),
          (data) => setLoadingConversion(data)
        );

        countryObj &&
          currencyListExchange(
            active[0].payment_option == "MTN Mobile Money"
              ? countryObj[0].code
              : theCountry.currencyCode,
            theCountry.currencyCode,
            (exchange) => setListingExchange(exchange),
            (err) => setError("Currency exchange error, please retry"),
            (data) => setLoadingConversion(data)
          );
        theCountry &&
          currencyListExchange(
            theCountry.currencyCode,
            "USD",
            (exchange) => setPaypalUSDListingConv(exchange),
            (err) => logger.log("Currency exchange error, please retry"),
            (data) => logger.log(data)
          );

        setSelectedPayment(active[0]);
      } else if (sortedPayment.length > 0) {
        const countryObj = currencyCodes2().filter(
          (x) => x.ctry === sortedPayment[0].payment_credential.country
        );

        const theCountry1 = Regions().filter(
          (ctry) => ctry.country === mylisting.country
        )[0];

        theCountry &&
          currencyListExchange(
            theCountry1.currencyCode,
            "USD",
            (exchange) => setPaypalUSDListingConv(exchange),
            (err) => logger.log("Currency exchange error, please retry"),
            (data) => logger.log(data)
          );

        const currencyTo = "EUR";
        countryObj[0]
          ? currencyExchange(
            countryObj[0].code,
            currencyTo,
            (exchange) => setCurrencyEuroExchangeRate(exchange),
            (err) => setError("Currency exchange error, please retry"),
            (data) => setLoadingConversion(data)
          )
          : currencyExchange(
            theCountry1.currencyCode,
            currencyTo,
            (exchange) => setCurrencyEuroExchangeRate(exchange),
            (err) => setError("Currency exchange error, please retry"),
            (data) => setLoadingConversion(data)
          );

        countryObj[0]
          ? currencyListExchange(
            countryObj[0].code,
            theCountry.currencyCode,
            (exchange) => setListingExchange(exchange),
            (err) => setError("Currency exchange error, please retry"),
            (data) => setLoadingConversion(data)
          )
          : currencyListExchange(
            theCountry1.currencyCode,
            theCountry.currencyCode,
            (exchange) => setListingExchange(exchange),
            (err) => setError("Currency exchange error, please retry"),
            (data) => setLoadingConversion(data)
          );

        setSender_currency(
          countryObj[0] ? countryObj[0].code : theCountry1.currencyCode
        );
        setSelectedPayment(sortedPayment[0]);
      }

      if (signLease.landlord_reject_cancel) {
        setRejectCancel(true);
        setInterval(() => {
          setRejectCancel(false);
        }, 15000);
      } else {
        setRejectCancel(false);
      }

      await putLandlordRejectCancel({
        applSign: signLease,
        leaseSign: detailLease,
      });

      /////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////
      ////////////////////BUILD PAYPAL SCRIPT
      const { data: cred } = await getPaypalCredentials();
      // logger.log('HERE ARE THE PAYPAL CREDENTIAL===', cred)
      // setPaypalClientId(cred.PAYPAL_CLIENT_ID)
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        cred &&
        cred.PAYPAL_CLIENT_ID &&
        `https://www.paypal.com/sdk/js?client-id=${cred.PAYPAL_CLIENT_ID}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };

      document.body.appendChild(script);

      const { data: spaces } = await clientGetAvailableSpaces(mylisting.id);
      setAvailableSpace(spaces);

      for (let i = 0; i < spaces.length; i++) {
        if (
          spaces[i].all_occupants.some(
            (occ) => occ.occupant_id === getCurrentUser()?.id
          )
        ) {
          setTenantSpace(spaces[i]);
          break;
        } else {
        }
      }

      setLoadingPayments(false);
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        history.push("/notFound");
      }
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // function capitalise first characters
  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //function  to chosee a selected payment method
  const onselectPaymentMethod = (payment) => {
    try {
      // logger.log('HERE IS THE METHOD==', payment)
      if (payment.payment_option == "MTN Mobile Money") {
        setSelectedPayment(payment);

        const countryObj = currencyCodes2().filter(
          (x) => x.ctry === payment.payment_credential.country
        );
        setSender_currency(countryObj[0].code);

        countryObj &&
          currencyListExchange(
            countryObj[0].code,
            listCurrencyCode,
            (exchange) => setListingExchange(exchange),
            (err) => logger.log(err),
            (data) => setLoadingConversion(data)
          );

        const currencyTo = "EUR";
        countryObj &&
          currencyExchange(
            countryObj[0].code,
            currencyTo,
            (exchange) => setCurrencyEuroExchangeRate(exchange),
            (err) => setError("Currency exchange error, please retry"),
            (data) => setLoadingConversion(data)
          );
      } else {
        setSelectedPayment(payment);
        const theCountry = Regions().filter(
          (ctry) => ctry.country === listing.country
        )[0];
        // logger.log('HERE IS THE COUNTRY CODE===', theCountry)
        const countryObj = currencyCodes2().filter(
          (x) => x.ctry === payment.payment_credential.country
        );
        setSender_currency(theCountry.currencyCode);

        countryObj &&
          currencyListExchange(
            theCountry.currencyCode,
            listCurrencyCode,
            (exchange) => setListingExchange(exchange),
            (err) => logger.log(err),
            (data) => setLoadingConversion(data)
          );

        const currencyTo = "EUR";
        countryObj &&
          currencyExchange(
            theCountry.currencyCode,
            currencyTo,
            (exchange) => setCurrencyEuroExchangeRate(exchange),
            (err) => setError("Currency exchange error, please retry"),
            (data) => setLoadingConversion(data)
          );
      }
    } catch (error) { }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //function to close sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //function to open sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  const currencyExchangeToEur = async (e) => {
    setSender_currency(e.target.value);
    const currencyTo = "EUR";
    currencyExchange(
      e.target.value,
      currencyTo,
      (exchange) => setCurrencyEuroExchangeRate(exchange),
      (err) => setError(err),
      (data) => setLoadingConversion(data)
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //function to get the date the leases starts the date it ends
  const dateLeaseStartAndExpiry = () => {
    const datee = detailLeaseSign.start_date
      ? new Date(detailLeaseSign.start_date.slice(0, 10))
      : new Date();
    const yearLeaseStart = datee.getFullYear();
    const monthLeaseStart = datee.getMonth();
    const dayLeaseStart = detailLeaseSign.start_date
      ? detailLeaseSign.start_date.slice(8, 10)
      : "";

    const endDatee = detailLeaseSign.end_date
      ? new Date(detailLeaseSign.end_date.slice(0, 10))
      : "";
    const yearLeaseEnd = endDatee ? endDatee.getFullYear() : "";
    const monthLeaseEnd = endDatee ? endDatee.getMonth() : "";
    const dayLeaseEnd = detailLeaseSign.end_date
      ? detailLeaseSign.end_date.slice(8, 10)
      : "";

    return {
      startDate: `${dayLeaseStart}/${monthLeaseStart + 1}/${yearLeaseStart}`,
      endDate: endDatee
        ? `${dayLeaseEnd}/${monthLeaseEnd + 1}/${yearLeaseEnd}`
        : "",
    };
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //function to calculate the duration which the tenant have stayed in the house
  const durationStayed = () => {
    const datee = detailLeaseSign.start_date
      ? new Date(detailLeaseSign.start_date.slice(0, 10))
      : new Date();
    const today =
      detailLeaseSign.tenant_close_deal &&
        detailLeaseSign.landlord_reject_cancel
        ? new Date(detailLeaseSign.end_date)
        : new Date();
    const yearLeaseStart = datee.getFullYear();
    const monthLeaseStart = datee.getMonth();
    const dayLeaseStart = detailLeaseSign.start_date
      ? detailLeaseSign.start_date.slice(8, 10)
      : "";
    const past = new Date(yearLeaseStart, monthLeaseStart, dayLeaseStart);

    const agreementCloseDate = detailLeaseSign.lease_cancel_date
      ? new Date(detailLeaseSign.lease_cancel_date)
      : "";
    let closeYear;
    let closeMonth;
    let closeDay;
    let closing;
    if (agreementCloseDate) {
      closeYear = agreementCloseDate.getFullYear();
      closeMonth = agreementCloseDate.getMonth();
      closeDay = detailLeaseSign.lease_cancel_date
        ? detailLeaseSign.lease_cancel_date.slice(8, 10)
        : "";
      closing = new Date(closeYear, closeMonth, closeDay);
    }

    const valueDuration = agreementCloseDate
      ? closing.getTime()
      : today.getTime();

    const diff = Math.floor(valueDuration - past.getTime());

    const day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff / day);
    const months = Math.floor(diff / (day * 30));

    return months;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //function involve in searching payment
  const onChangeSearchAmount = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableAmount(value, payments);
      setPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);

        const { data: signLease } = await getASignLeaseById(match.params.id);
        setMySignLease(signLease);

        const { data: detailLease } = await getALeaseSignById(
          signLease.application_id
        );

        setDetailLeaseSign(detailLease);

        const { data: landlordPaymentMethod } = await getLandlordsPaymentMethod(
          signLease.list_owner_id
        );
        setLandlordPayment(landlordPaymentMethod);

        const { data: mylisting } = await getListingsById(signLease.listing_id);
        setListing(mylisting);

        const { data: myPaymentMethods } = await getAllUsersPaymentMethods();
        const sortedPayment = _.orderBy(
          myPaymentMethods,
          [sortColumns.path],
          [sortColumns.order]
        );
        setTenantPaymentMethods(sortedPayment);

        const listOwner = signLease
          ? await getUserById(signLease.list_owner_id)
          : "";
        setLandlordDetails(listOwner.data);

        const theCountry = Regions().filter(
          (ctry) => ctry.country === mylisting.country
        )[0];

        setListCurrencyCode(theCountry.currencyCode);

        const { data: myPaymentsForThisLease } =
          await getUsersPaymentsByLeaseId(detailLease.id);
        // const {data:myPaymentsForThisLease}=await getPaymentingByApplicationId(signLease.application_id)
        const sortedPayments = _.orderBy(
          myPaymentsForThisLease,
          [sortColumn.path],
          [sortColumn.order]
        );
        setPayments(sortedPayments);

        const { data: applStatusPaymentDetails } =
          await getPaymentDetailsPayerById(
            getCurrentUser() && getCurrentUser().id
          );

        setPaymentDetails(applStatusPaymentDetails);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableAmount = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].amount.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //filter payment by the date paid
  const onChangeSearchPayDate = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableCreatedDate(value, payments);
      setPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);

        const { data: signLease } = await getASignLeaseById(match.params.id);
        setMySignLease(signLease);

        const { data: detailLease } = await getALeaseSignById(
          signLease.application_id
        );

        setDetailLeaseSign(detailLease);

        const { data: landlordPaymentMethod } = await getLandlordsPaymentMethod(
          signLease.list_owner_id
        );
        setLandlordPayment(landlordPaymentMethod);

        const { data: mylisting } = await getListingsById(signLease.listing_id);
        setListing(mylisting);

        const { data: myPaymentMethods } = await getAllUsersPaymentMethods();
        const sortedPayment = _.orderBy(
          myPaymentMethods,
          [sortColumns.path],
          [sortColumns.order]
        );
        setTenantPaymentMethods(sortedPayment);

        const listOwner = signLease
          ? await getUserById(signLease.list_owner_id)
          : "";
        setLandlordDetails(listOwner.data);

        const theCountry = Regions().filter(
          (ctry) => ctry.country === mylisting.country
        )[0];

        setListCurrencyCode(theCountry.currencyCode);

        const { data: myPaymentsForThisLease } =
          await getUsersPaymentsByLeaseId(detailLease.id);
        // const {data:myPaymentsForThisLease}=await getPaymentingByApplicationId(signLease.application_id)
        const sortedPayments = _.orderBy(
          myPaymentsForThisLease,
          [sortColumn.path],
          [sortColumn.order]
        );
        setPayments(sortedPayments);

        const { data: applStatusPaymentDetails } =
          await getPaymentDetailsPayerById(
            getCurrentUser() && getCurrentUser().id
          );

        setPaymentDetails(applStatusPaymentDetails);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableCreatedDate = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].create_date.toLowerCase();
      const myTitle = myDateToSort(title);
      if (myTitle.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //search payment by their payment method either mobile money or paypal or momo
  const onChangeSearchPaymentMethod = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTablePaymentMethod(value, payments);
      setPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);

        const { data: signLease } = await getASignLeaseById(match.params.id);
        setMySignLease(signLease);

        const { data: detailLease } = await getALeaseSignById(
          signLease.application_id
        );

        setDetailLeaseSign(detailLease);

        const { data: landlordPaymentMethod } = await getLandlordsPaymentMethod(
          signLease.list_owner_id
        );
        setLandlordPayment(landlordPaymentMethod);

        const { data: mylisting } = await getListingsById(signLease.listing_id);
        setListing(mylisting);

        const { data: myPaymentMethods } = await getAllUsersPaymentMethods();
        const sortedPayment = _.orderBy(
          myPaymentMethods,
          [sortColumns.path],
          [sortColumns.order]
        );
        setTenantPaymentMethods(sortedPayment);

        const listOwner = signLease
          ? await getUserById(signLease.list_owner_id)
          : "";
        setLandlordDetails(listOwner.data);

        const theCountry = Regions().filter(
          (ctry) => ctry.country === mylisting.country
        )[0];

        setListCurrencyCode(theCountry.currencyCode);

        const { data: myPaymentsForThisLease } =
          await getUsersPaymentsByLeaseId(detailLease.id);

        const sortedPayments = _.orderBy(
          myPaymentsForThisLease,
          [sortColumn.path],
          [sortColumn.order]
        );
        setPayments(sortedPayments);

        const { data: applStatusPaymentDetails } =
          await getPaymentDetailsPayerById(
            getCurrentUser() && getCurrentUser().id
          );

        setPaymentDetails(applStatusPaymentDetails);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTablePaymentMethod = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].payment_method.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  ///////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////
  //function to sort payment base on date amount and payment method
  const onChangeSort = (e) => {
    e.preventDefault();
    const paying = [...payments];
    const { value } = e.currentTarget;
    const column = { ...sortColumns };
    column.path = value;
    const sortedPayment = _.orderBy(paying, [column.path], [column.order]);
    setPayments(sortedPayment);
  };

  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  //function to download the receipt
  const ondownloadReceipt = async (payment) => {
    payment.listCurrencyCode = listCurrencyCode;
    setSelectedPaymentToLandlord(payment);
    await postAndDownloadPaymentService(payment, (data) =>
      setSelectedPaymentToLandlord(data)
    );
  };

  /////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  //function to execute drop to show payment details
  const onClickPayment = (payment) => {
    const myPays = [...selectedPaymentTable];
    if (myPays.includes(payment)) {
      const remainingPay = myPays.filter((pay) => pay !== payment);
      setSelectedPaymentTable(remainingPay);
      trackEvent(
        "Btn show payment details dropdown",
        "dropdown",
        "dropdown Btn"
      );
    } else {
      myPays.push(payment);
      setSelectedPaymentTable(myPays);
      trackEvent(
        "Btn show payment details dropdown",
        "dropdown",
        "dropdown Btn"
      );
    }
  };

  ////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  //funtion responsible for tenant closing deal
  const onClickCloseDeal = async () => {
    const data = {
      tenant: getCurrentUser(),
      listing,
      application_sign: mySignLease,
      lease_signed: detailLeaseSign,
      landlord: landlordDetails,
      tenant_space: tenantSpace,
    };
    setLoadingCloseDeal(true);
    socket.emit(
      "tenant_closing_deal",
      data,
      ({ error, appl_sign, leaseSign }) => {
        setMySignLease(appl_sign);
        setDetailLeaseSign(leaseSign);
        setLoadingCloseDeal(false);
        if (appl_sign.tenant_close_deal && appl_sign.landlord_close_deal) {
          setCloseDeal(true);
        } else {
          setCloseDeal(false);
        }
      }
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  //tenant reject agreement Cancellation\
  const onClickRejectCancellation = () => {
    const data = {
      tenant: getCurrentUser(),
      listing,
      application_sign: mySignLease,
      lease_signed: detailLeaseSign,
      landlord: landlordDetails,
    };
    setLoadingCloseDeal(true);

    socket.emit(
      "tenant_closing_deal_reject",
      data,
      ({ error, appl_sign, leaseSign }) => {
        setMySignLease(appl_sign);
        setDetailLeaseSign(leaseSign);
        setLoadingCloseDeal(false);
        if (appl_sign.tenant_close_deal && appl_sign.landlord_close_deal) {
          setCloseDeal(true);
        } else {
          setCloseDeal(false);
        }
      }
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //submit price to the backend
  const onSubmitPayment = async (e) => {
    e.preventDefault();
    setConfirmPassword(false);
    if (selectedPayment.payment_option == "MTN Mobile Money") {
      ////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////
      ///IF A USER PAYS WITH MOBILE MONEY IT FALLS HERE
      if (
        listing.rent_or_sell === "Sale" &&
        Number(mySignLease.total_amount_paid) >= Number(listing.price)
      ) {
        setError(t("You_Can't_Make"));
      } else {
        if (!parseInt(amount)) return setError(t("Amount_is_not"));
        if (parseInt(amount) <= 0) return setError(t("Amount_is_must_be"));
        if (Number(amount) < Number(listing.price) / 4)
          return setError(
            "You must pay at least 25% of the price for this listing"
          );
        if (!currencyEuroExchangeRate) {
          return setError(t("Currency_is_not_allowed"));
        }

        if (
          listCurrencyCode !== sender_currency &&
          selectedPayment.payment_option == "MTN Mobile Money"
        ) {
          return setError(
            "Please, your must have a Mobile Money Account in the country location of this listing, for our Algorithm to effectively work.Please, we are working on it, open a Mobile money account in the location of this listing."
          );
        }

        const totalAmt = Number(currencyEuroExchangeRate) * Number(amount);
        const usdAmount = Number(amount) * Number(paypalUSDListingConv);
        try {
          const paymentData = {
            amount: totalAmt,
            payment_method: selectedPayment,
            landlord: landlordDetails,
            sender: getCurrentUser(),
            listing,
            lease_signed: mySignLease,
            lease_signed_details: detailLeaseSign,
            listCurrencyCode,
            sender_currency,
            amt_no_eur: Number(amount),
            currentEuroExchangeRate: currencyEuroExchangeRate,
            currentUSDExchangeRate: paypalUSDListingConv,
            amountPaidInEuro: Number(currencyEuroExchangeRate) * Number(amount),
            application_id: mySignLease.application_id,
            listingExchange,
            amount_on_list_exchamge: listingExchange * Number(amount),
            amountToUSD: usdAmount.toFixed(2),
          };

          setLoadingPayment(true);

          socket.emit(
            "tenantPaybills",
            paymentData,
            async ({ error, leaseSigned, paymentDetails, paidData, accountBalance: balance }) => {
              setError("");
              if (error) {
                setLoadingPayment(false);
                setError(error);
              } else {
                const sortedPayment = _.orderBy(
                  paidData,
                  [sortColumn.path],
                  [sortColumn.order]
                );

                setPayments(sortedPayment);
                setMySignLease(leaseSigned);
                setPaymentDetails(paymentDetails);
                const myVideoToPlay = document.getElementById("myVideoPay");
                myVideoToPlay && myVideoToPlay.play();
                setLoadingPayment(false);
                setPaid(true);
                // setAmount("");
                // setSelectedPayment('')
                setTimeout(() => {
                  setPaid(false);
                }, 5000);

                if (accountBalance) {
                  setAccountBalance(balance)
                }

                ////tenant get all leases applications that are owed
                const { data: myLeasesAppl } = await getStatusByApplicantsId(
                  getCurrentUser()?.id
                );
                const leaseOwing = myLeasesAppl.filter(
                  (p) => Number(p.amount_owed) > 0
                );
                setMyLeasesApplicationsOwed(leaseOwing);
              }
            }
          );

          trackEvent(
            "Tenant make payment in Leasing details",
            totalAmt,
            "PAY BTN"
          );
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            setError(ex.response.data);
            setLoadingPayment(false);
            setPaid(false);
          }
        }
      }
    } else {
      ////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////
      ///IF A USER PAYS WITH VISA CARD< IT FALLS HERE
      if (
        listing.rent_or_sell === "Sale" &&
        Number(mySignLease.total_amount_paid) >= Number(listing.price)
      ) {
        setError(t("You_Can't_Make"));
      } else {
        if (!parseInt(amount)) return setError(t("Amount_is_not"));
        if (parseInt(amount) <= 0) return setError(t("Amount_is_must_be"));
        if (Number(amount) < Number(listing.price) / 4)
          return setError(
            "You must pay at least 25% of the price for this listing"
          );
        if (!currencyEuroExchangeRate) {
          return setError(t("Currency_is_not_allowed"));
        }

        const totalAmt = Number(amount);
        const usdAmount = Number(amount) * Number(paypalUSDListingConv);
        try {
          const paymentData = {
            amount: totalAmt,
            payment_method: selectedPayment,
            landlord: landlordDetails,
            sender: getCurrentUser(),
            listing,
            lease_signed: mySignLease,
            lease_signed_details: detailLeaseSign,
            listCurrencyCode,
            sender_currency,
            amt_no_eur: Number(amount),
            currentEuroExchangeRate: currencyEuroExchangeRate,
            currentUSDExchangeRate: paypalUSDListingConv,
            amountPaidInEuro: Number(currencyEuroExchangeRate) * Number(amount),
            application_id: mySignLease.application_id,
            listingExchange,
            amount_on_list_exchamge: listingExchange * Number(amount),
            amountToUSD: usdAmount.toFixed(2),
          };

          setLoadingPayment(true);

          socket.emit(
            "tenantPaybillsVisaCard",
            paymentData,
            ({ error, URL }) => {
              setError("");
              if (error) {
                // setLoadingPayment(false)
                // setError(error)
              } else {
                localStorage.setItem(`payingWithStripe`, `${totalAmt}`);
                window.location.assign(URL);
              }
            }
          );

          trackEvent(
            "Tenant make payment in Leasing details",
            totalAmt,
            "PAY BTN"
          );
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            setError(ex.response.data);
            setLoadingPayment(false);
            setPaid(false);
          }
        }
      }
    }
  };

  const onSuccess = async (details, data) => {
    if (
      listing.rent_or_sell === "Sale" &&
      Number(mySignLease.total_amount_paid) >= Number(listing.price)
    ) {
      setError(t("You_Can't_Make"));
    } else {
      if (!parseInt(amount)) return setError(t("Amount_is_not"));
      if (parseInt(amount) <= 0) return setError(t("Amount_is_must_be"));
      if (Number(amount) < Number(listing.price) / 4)
        return setError(
          "You must pay at least 25% of the price for this listing"
        );

      const totalAmt = Number(amount);
      const usdAmount = Number(amount) * Number(paypalUSDListingConv);

      try {
        const paymentData = {
          amount: totalAmt,
          payment_method: selectedPayment,
          landlord: landlordDetails,
          sender: getCurrentUser(),
          listing,
          lease_signed: mySignLease,
          lease_signed_details: detailLeaseSign,
          listCurrencyCode,
          sender_currency,
          amt_no_eur: Number(amount),
          currentEuroExchangeRate: currencyEuroExchangeRate,
          currentUSDExchangeRate: paypalUSDListingConv,
          amountPaidInEuro: Number(currencyEuroExchangeRate) * Number(amount),
          amountToUSD: usdAmount.toFixed(2),
          application_id: mySignLease.application_id,
          listingExchange,
          amount_on_list_exchamge: listingExchange * Number(amount),
          paypal_data: {
            data,
            details,
          },
        };

        setLoadingPayment(true);
        socket.emit(
          "tenantPaybills",
          paymentData,
          async ({ error, leaseSigned, paymentDetails, paidData }) => {
            setError("");
            if (error) {
              setLoadingPayment(false);
              setError(error);
            } else {
              const sortedPayment = _.orderBy(
                paidData,
                [sortColumn.path],
                [sortColumn.order]
              );

              setPayments(sortedPayment);
              setMySignLease(leaseSigned);
              setPaymentDetails(paymentDetails);
              const myVideoToPlay = document.getElementById("myVideoPay");
              myVideoToPlay && myVideoToPlay.play();
              setLoadingPayment(false);
              setPaid(true);
              // setAmount("");
              // setSelectedPayment('')
              setTimeout(() => {
                setPaid(false);
              }, 5000);

              ////tenant get all leases applications that are owed
              const { data: myLeasesAppl } = await getStatusByApplicantsId(
                getCurrentUser()?.id
              );
              const leaseOwing = myLeasesAppl.filter(
                (p) => Number(p.amount_owed) > 0
              );
              setMyLeasesApplicationsOwed(leaseOwing);
            }
          }
        );

        trackEvent(
          "Tenant make payment in Leasing details",
          totalAmt,
          "PAYPAL"
        );
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          setError(ex.response.data);
          setLoadingPayment(false);
          setPaid(false);
        }
      }
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  const myDateHasEnded = () => {
    const myEndDate = detailLeaseSign.end_date;
    const myYearEnd = Number(`${myEndDate}`.slice(0, 4));
    let myMonthsEnd = Number(`${myEndDate}`.slice(5, 7)) - 1;
    const myDaysEnd = Number(`${myEndDate}`.slice(8, 10));

    const todayEnd = new Date();
    const pastEnd = new Date(myYearEnd, myMonthsEnd, myDaysEnd);

    const diffEnd = Math.floor(todayEnd.getTime() - pastEnd.getTime());
    const dayEnd = 1000 * 60 * 60 * 24;
    var daysEnd = Math.floor(diffEnd / dayEnd);

    if (listing.sold_agreement) {
      return false;
    }

    return daysEnd > 0 ? true : false;
  };

  const onSetConfirmPay = (e) => {
    e.preventDefault();
    if (`${amount}`.startsWith("-"))
      return setError("Amount must be an interger");
    if (amount.length == 0) return setError("Amount must not be empty");
    setConfirmPassword(true);
    // onSubmitPayment
  };

  const onSubmitPassword = async () => {
    try {
      setConfirmLoading(true);
      const { data } = await userConfirmPassword({
        password: confirmPasswordData,
        listing,
      });
      setConfirmLoading(false);

      if (data) {
        onSubmitPayment();
      } else {
        const chan = chances - 1;
        if (chan === 0) return history.push("/logout");
        setChances(chan);
        setError(`Invalid password, please you have ${chan} chances remaining`);
      }
    } catch (ex) {
      if (ex.response.status === 400) setError(ex.response.data);
    }
  };

  const onSubmitPassword1 = async () => {
    try {
      setConfirmLoading(true);
      const { data } = await userConfirmPassword({
        password: confirmPasswordData,
        listing,
      });
      setConfirmLoading(false);

      if (data) {
        setConfirmPasswordPay(data);
        setConfirmPassword(false);
        setError("");
      } else {
        const chan = chances - 1;
        if (chan === 0) return history.push("/logout");
        setChances(chan);
        setError(`Invalid password, please you have ${chan} chances remaining`);
      }
    } catch (ex) {
      if (ex.response.status === 400) setError(ex.response.data);
    }
  };

  if (loadingPayments)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
      </div>
    );
  if (underConstruction)
    return (
      <>
        <Meta title="Nawafrica|Users Payment Details" />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <>
      <Meta1
        title={`My Lease And Payment Details For ${listing.title}`}
        description={`My Lease And Payment Details For ${listing.title}`}
        keywords="Lease Payment Details"
        link={`/portal/tenant/leases/${mySignLease.id}`}
      />
      {rejectCancel && !closeDeal && (
        <AlertCustomise data="The Landlord Rejected the Cancellation of this Signed Lease. Please, inform this Landlord To Accept The Cancellation of This Signed Lease" />
      )}
      {requestToClose && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "80px",
            position: "fixed",
            zIndex: "5000",
          }}
        >
          <CancelAgreementAlert
            names={mySignLease.list_owner_name}
            date={`${mySignLease.create_date.slice(
              8,
              10
            )}/${mySignLease.create_date.slice(
              5,
              7
            )}/${mySignLease.create_date.slice(0, 4)}`}
            listingName={listing.title}
            tenant={true}
          />
        </div>
      )}
      <div>
        <audio id="myVideoPay" src="/images/shhhhh-40064.mp3"></audio>
      </div>

      <MakePaymentModal
        landlordDetails={landlordDetails}
        confirmPasswordPay={confirmPasswordPay}
        confirmLoading={confirmLoading}
        paypalAmount={`${(
          Number(amount) * Number(paypalUSDListingConv)
        ).toFixed(2)}`}
        onSuccess={onSuccess}
        sdkReady={sdkReady}
        listing={listing}
        tenantPaymentMethods={tenantPaymentMethods}
        onselectPaymentMethod={onselectPaymentMethod}
        selectedPayment={selectedPayment}
        onSubmitPayment={onSubmitPayment}
        onChangeAmount={(e) => {
          setAmount(e.target.value);
        }}
        valueAmount={amount}
        loadingPayment={loadingPayment}
        paid={paid}
        onClickPaid={() => setPaid(false)}
        error={error}
        onChangeCurrency={currencyExchangeToEur}
        valueCurrency={currencyEuroExchangeRate}
        loadingConversion={loadingConversion}
        paypalUSDListingConv={paypalUSDListingConv}
        paypalClientId={paypalClientId}
        confirmPassword={confirmPassword}
        onClickConfirmPayment={onSubmitPassword}
        onClickConfirmPayment1={onSubmitPassword1}
        onChangeConfirmPasswordData={(e) =>
          setConfirmPasswordData(e.target.value)
        }
        handleChangePercent={(e) => {
          setAmountPercent(e.target.value);
          if (numberOfMonths <= 0) setNumberOfMonths(1);
          let numberOfMth =
            Number(numberOfMonths) <= 0 ? 1 : Number(numberOfMonths);
          if (e.target.value == 1) {
            setAmount(
              Number(e.target.value) * Number(listing.price) * numberOfMth
            );
          } else {
            setAmount(Number(e.target.value) * Number(listing.price));
            setNumberOfMonths(1);
          }
        }}
        onChangeAmount1={(e) => {
          setNumberOfMonths(e.target.value);

          let numberOfMth = e.target.value <= 0 ? 1 : Number(e.target.value);

          setAmount(
            Number(amountPercent) * Number(listing.price) * numberOfMth
          );
        }}
        amountPercent={amountPercent}
        numberOfMonths={numberOfMonths}
        onChangeQuantity={(e) => {
          setQuantity(e.target.value);
          setAmount(Number(e.target.value) * Number(listing.price));
        }}
        quantity={quantity}
      />

      <Meta
        title={t("Nawafrica_name_signed_leases", {
          name: getCurrentUser() && getCurrentUser().first_name,
        })}
      />

      {close && (
        <SidebarTenant
          onClickCloseBar={onClickCloseBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButtonTenant
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <div className="leasingDetailsOuterContainer">
        {/* ////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
      THE IS SIGN THAT WILL BE SHOWN AFTER THE LANDLORD AND THE TENANT HAVE CLOSED THE DEAL */}
        {closeDeal && (
          <div
            style={{
              position: "fixed",
              marginTop: "100px",
              zIndex: "100",
              left: "17%",
            }}
          >
            <CloseDeal />
          </div>
        )}
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        <WelcomeUser
          user={capitalize(getCurrentUser() && getCurrentUser().first_name)}
        />

        <div
          className={
            close
              ? "leasingSignedDetInnerContainer"
              : "leasingSignedDetInnerContainer1"
          }
        >
          {/* <div className='alert alert-danger'>{t('WARNING')}</div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "1px 1px 1px 1px #d3d3d3",
            }}
            className={
              Number(mySignLease.amount_owed) <= 0
                ? "alert alert-info"
                : "alert alert-danger"
            }
          >
            <div style={{ marginRight: "5px" }}>
              {Number(mySignLease.amount_owed) <= 0 ? (
                <DebtFree />
              ) : (
                <OwingAnimation />
              )}
            </div>
            {t("List_of_payments_made_to", {
              landlord: capitalize(
                mySignLease && mySignLease.list_owner_name
                  ? mySignLease.list_owner_name
                  : ""
              ),
              cite: capitalize(
                mySignLease && mySignLease.listing_name
                  ? mySignLease.listing_name
                  : ""
              ),
            })}{" "}
          </div>

          <SearchPaymentLeaseSign
            onChangeSearchAmount={onChangeSearchAmount}
            onChangeSearchPayDate={onChangeSearchPayDate}
            onChangeSearchPaymentMethod={onChangeSearchPaymentMethod}
            onChangeSort={onChangeSort}
            currency={listing.currency}
            closeDeal={closeDeal}
          />

          {payments.length > 0 ? (
            <TableLeaseSignDet
              payments={payments}
              paymentDetails={paymentDetails}
              listingCurrency={listing.currency}
              loadingSearchPayment={loadingSearchPayment}
              ondownloadReceipt={ondownloadReceipt}
              selectedPaymentToLandlord={selectedPaymentToLandlord}
              onClickPayment={onClickPayment}
              selectedPaymentTable={selectedPaymentTable}
            />
          ) : loadingSearchPayment ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              {t(".....loading")}
            </div>
          ) : (
            <div className="noListingAvailableYetCon someNopaymentMadeInleaseSign">
              <div className="alert">
                {t("No_Payment_Made_Yet_For_This_Lease")}
                {!closeDeal && (
                  <button
                    data-toggle="modal"
                    data-target="#exampleModalMakePayment"
                    className="btn btn-light"
                  >
                    {t("Please_Make_A_Payment")}
                  </button>
                )}
              </div>
            </div>
          )}

          <ListingInfo
            leaseMonthEnds={myDateHasEnded()}
            onClickRejectCancellation={onClickRejectCancellation}
            onClickCloseDeal={onClickCloseDeal}
            listing={listing}
            requestToClose={requestToClose}
            landlordDetails={landlordDetails}
            dateLeaseStartAndExpiry={dateLeaseStartAndExpiry}
            detailLeaseSign={detailLeaseSign}
            mySignLease={mySignLease}
            monthsCover={durationStayed()}
            loadingCloseDeal={loadingCloseDeal}
            closeDeal={closeDeal}
            cancelDate={
              mySignLease && mySignLease.lease_cancel_date
                ? `${mySignLease &&
                mySignLease.lease_cancel_date &&
                mySignLease.lease_cancel_date.slice(8, 10)
                }/${mySignLease &&
                mySignLease.lease_cancel_date &&
                mySignLease.lease_cancel_date.slice(5, 7)
                }/${mySignLease &&
                mySignLease.lease_cancel_date &&
                mySignLease.lease_cancel_date.slice(0, 4)
                }`
                : null
            }
            tenantSpace={tenantSpace}
            availableSpace={availableSpace}
          />
        </div>
      </div>
      <ScrollToTop smooth />
      {/* <div className='letMeGiveSomeSeparationOverAccountable'></div> */}
    </>
  );
};

export default LeaseSignDetails;
