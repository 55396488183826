import React from "react";
import Picker from "emoji-picker-react";
import { FaPaperclip } from "react-icons/fa";
import { FaMeh } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import SpinnerPic from "./../AddListing/Spinner";
import { Image } from "cloudinary-react";

import "./messages.css";
import SentMsgLoader from "./SentMsgLoader";
import SentMsgDone from "./SentMsgDone";

const InboxModal = ({
  onChangeSubject,
  ondeletePicture,
  onChangeMessage,
  onChangeImages,
  onEmojiClick,
  loadOtherPhoto,
  otherPhotoPath,
  message,
  onSubmitMessage,
  valueSubject,
  submitError,
  loadSendingMsg,
  messageDone,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="modal fade"
      id="exampleModalInbox"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content modalContentInComposeMsg">
          <form onSubmit={onSubmitMessage}>
            <div className="modal-header">
              <h5
                className="modal-title myHeadingToComposeMsg"
                id="exampleModalLabel"
              >
                {t("Reply_Message")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body theBodyContainerOfComposeMsg">
              {loadSendingMsg ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SentMsgLoader />
                </div>
              ) : messageDone ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SentMsgDone />
                </div>
              ) : (
                <>
                  <input
                    placeholder={t("Subject")}
                    value={valueSubject}
                    onChange={onChangeSubject}
                    className="form-control subjectInputInComposeMsg"
                  />
                  <textarea
                    value={message}
                    onChange={onChangeMessage}
                    placeholder={t("Text_Message")}
                    className="form-control theMessageTextAreaInComposeMsg"
                  />

                  <div className="imagesTosendInComposeMsg">
                    {otherPhotoPath && (
                      <div className="fileInputMultipleImgMessage">
                        {loadOtherPhoto && (
                          <div>
                            <SpinnerPic />
                          </div>
                        )}
                        {otherPhotoPath.map((src) => (
                          <a
                            key={src.public_id}
                            onClick={() => ondeletePicture(src)}
                            href="#"
                          >
                            {src.public_id ? (
                              <Image
                                className="overlay-image animate__animated"
                                cloudName="dw9gomx53"
                                publicId={src.public_id}
                                crossorigin="anonymous"
                                style={{ objectFit: "cover" }}
                              />
                            ) : (
                              <img
                                style={{ objectFit: "cover" }}
                                crossorigin="anonymous"
                                src={src.url}
                              />
                            )}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="footer theFooterContainerForComposeMsg">
              <div className="sendBtnContainerComposeMsg">
                <button disabled={loadSendingMsg} type="submit" className="btn">
                  <span style={{ marginRight: "5px" }}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </span>
                  {t("Send")}
                </button>
              </div>
              <div className="faPaperClipContainComposeMsg">
                <label htmlFor="myFileInputFieldCompose">
                  <FaPaperclip className="clipIcon" />
                </label>
              </div>
              <div className="faMehEmojiContainerComposeMsg">
                <div className="dropdown-menu">
                  <Picker
                    onEmojiClick={(event, emojiObject) =>
                      onEmojiClick(event, emojiObject)
                    }
                  />
                </div>
                <a
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FaMeh className="iconNumber2" />
                </a>
              </div>
              <input
                id="myFileInputFieldCompose"
                accept="image/*"
                className="fileUpload"
                name="file"
                onChange={onChangeImages}
                type="file"
              />
            </div>
            <div className="composeMessageErrorDisplay">{submitError}</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InboxModal;
