import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Icon, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GetAppIcon from '@mui/icons-material/GetApp';

const SelectedDocumentModal = ({
    open,
    handleClose,
    selectedDocument,
    onPrev,
    onNext
}) => {
    console.log(selectedDocument)
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <div style={{ width: 500, minHeight: 300, maxHeight: "auto", position: "relative" }}>
                    <IconButton
                        style={{
                            position: "absolute", top: "50%", left: 10, transform: "translateY(-50%)",
                            backgroundColor: "rgba(255, 255, 255, 1)​"
                        }}
                        onClick={() => onPrev(selectedDocument)}
                    >
                        <ChevronLeftIcon
                            style={{ fontSize: "45px" }}
                        />
                    </IconButton>
                    <IconButton
                        style={{
                            position: "absolute", top: "50%", right: 10, transform: "translateY(-50%)",
                            backgroundColor: "rgba(255, 255, 255, 1)​"
                        }}
                        onClick={() => onNext(selectedDocument)}
                    >
                        <ChevronRightIcon
                            style={{ fontSize: "45px" }}
                        />
                    </IconButton>
                    <a
                        href={`https://res.cloudinary.com/dw9gomx53/image/upload/fl_attachment/${selectedDocument?.public_id}.${selectedDocument?.format}`}
                    >

                        <IconButton
                            style={{
                                position: "absolute", top: 10, right: 10,
                                backgroundColor: "rgba(255, 255, 255, 1)​"
                            }}
                        >
                            <GetAppIcon
                                style={{ fontSize: "30px" }}
                            />
                        </IconButton>

                    </a>
                    <img src={selectedDocument?.secure_url} style={{ width: 500, height: "auto" }} />
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default SelectedDocumentModal
