import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import io from "socket.io-client";

import {
  getALeaseSignById,
  getASignLeaseById,
  getStatusByApplicantsId,
} from "../Services/applicationStatus";
import { getListingsById } from "../Services/listings";
import { getCurrentUser } from "../Services/authService";
import { getUserById } from "../Services/registerService";
import { getAllUsersPaymentMethods } from "../Services/paymentOptions";
import { Regions } from "../Utils/regions";
import logger from "../Services/loggerService";
import UnreadMsgsContext from "../unreadmessages/context";

const LeaseSignStripeSuccess = ({ match }) => {
  const { setMyLeasesApplicationsOwed, unreadMsgs } =
    useContext(UnreadMsgsContext);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      if (localStorage && localStorage.getItem("payingWithStripe")) {
        const sockets = io();
        const { data: signLease } = await getASignLeaseById(match.params.id);
        const { data: detailLease } = await getALeaseSignById(
          signLease.application_id
        );
        const { data: mylisting } = await getListingsById(signLease.listing_id);
        const listOwner = signLease
          ? await getUserById(signLease.list_owner_id)
          : "";

        const { data: myPaymentMethods } = await getAllUsersPaymentMethods();
        const usedCard = myPaymentMethods.filter(
          (p) => p.payment_credential.card_number === match.params.card_num
        )[0];

        const theCountry = Regions().filter(
          (ctry) => ctry.country === mylisting.country
        )[0];

        if (getCurrentUser()) {
          const paymentData = {
            amount: match.params.amount,
            payment_method: usedCard,
            landlord: listOwner.data,
            sender: getCurrentUser(),
            listing: mylisting,
            lease_signed: signLease,
            lease_signed_details: detailLease,
            listCurrencyCode: theCountry.currencyCode,
            sender_currency: theCountry.currencyCode,
            amt_no_eur: Number(match.params.amount),
            application_id: signLease.application_id,
            listingExchange: 1,
            amount_on_list_exchamge: 1 * Number(match.params.amount),
            currentEuroExchangeRate: match.params.eurExchange,
            amountToUSD: match.params.amountUSD,
            amountPaidInEuro:
              Number(match.params.amount) * Number(match.params.eurExchange),
            currentUSDExchangeRate:
              Number(match.params.amountUSD) / Number(match.params.amount),
          };
          localStorage && localStorage.removeItem("payingWithStripe");
          sockets.emit(
            "tenantPaybillsVisaCardPost",
            paymentData,
            async ({ error, leaseSigned, paymentDetails, paidData }) => {
              if (error) {
                logger.log(false);
                logger.log(error);
              } else {
                ////tenant get all leases applications that are owed
                const { data: myLeasesAppl } = await getStatusByApplicantsId(
                  getCurrentUser()?.id
                );
                const leaseOwing = myLeasesAppl.filter(
                  (p) => Number(p.amount_owed) > 0
                );
                setMyLeasesApplicationsOwed(leaseOwing);
              }
            }
          );
        }
      } else {
        window.location.assign(`/portal/tenant/leases/${match.params.id}`);
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "30vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        className="alert alert-info"
      >
        <div>
          <Link
            to={`/portal/tenant/leases/${match.params.id}`}
            className="btn btn-light"
            style={{ marginRight: "10px", color: "grey" }}
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              className="icon"
              icon={faArrowLeftLong}
            />
            Go Back
          </Link>
        </div>
        <div style={{ flex: 1 }}>Successfully made payment with Visa Card</div>
      </div>
    </div>
  );
};

export default LeaseSignStripeSuccess;
