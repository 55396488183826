import React, { useState, useEffect } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { FaUser, FaBars } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleRoof,
  faPersonShelter,
} from "@fortawesome/free-solid-svg-icons";
import io from "socket.io-client";
import Avatar from "@mui/material/Avatar";

import SignUpIcon from "../../iconComponent/SignUpIcon";
import Login from "../../iconComponent/Login";

import Globe from "./Globe";
import { getCurrentUser } from "../../Services/authService";
import { getUserById } from "../../Services/registerService";

import { trackEvent } from "../../Utils/useGAEventTracker";

import "./styleCompo.css";
import { Button } from "@mui/material";

const Navbar = () => {
  const [manageOrPortal, setManageOrPortal] = useState("");
  const [picPath, setPicPath] = useState("");
  const [firstName, setFirstName] = useState("");
  const [error, setError] = useState(false);

  const [cancelAgreement, setCancelAgreement] = useState(false);

  const [landlordOwingTenants, setLandlordOwingTenants] = useState([]);
  const [scroll, setScroll] = useState(0);

  const currentLanguageCode = cookies.get("i18next");

  const { t } = useTranslation();

  useEffect(() => {
    const itemFromLocalStorage = localStorage.getItem("portalOrManagement");
    setManageOrPortal(itemFromLocalStorage ? itemFromLocalStorage : "");
    window.onscroll = function (e) {
      setScroll(this.scrollY);
    };
    myProfile();
    return () => {
      localStorage.setItem("portalOrManagement", "");
    };
  }, []);

  const myProfile = async () => {
    try {
      const socket = io("/");
      const user_id = getCurrentUser() && getCurrentUser().id;

      if (user_id) {
        const { data: user } = await getUserById(user_id);

        setPicPath(user.picture);
        setFirstName(user.first_name);
        setError(false);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        setError(true);
      }
    }
  };

  const redirectTo = () => {
    const pathFromStorage = localStorage.getItem("portalPath");
    localStorage.setItem("portalOrManagement", "portal");
    trackEvent(
      "landlord portal click",
      pathFromStorage || "/portal/list/listing",
      "Navbar Link"
    );
    if (pathFromStorage) {
      window.location.assign(pathFromStorage);
    } else {
      window.location.assign("/portal/application/applicants");
    }
  };

  const redirectToTenantPortal = () => {
    const pathFromStorage = localStorage.getItem("portalPath");
    // setCancelAgreement(false)
    localStorage.setItem("portalOrManagement", "portal");
    trackEvent(
      "Tenant portal click",
      pathFromStorage || "/portal/tenant/leases",
      "Navbar Link"
    );
    if (pathFromStorage) {
      window.location.assign(pathFromStorage);
    } else {
      window.location.assign("/portal/tenant/leases");
    }
  };

  // function use to capitalise first letters
  const capitalize = (s) => {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  };

  const isLandlord = () => {
    if (getCurrentUser()) {
      if (getCurrentUser().post_rent_property === "I AM A PROPERTY OWNER") {
        return "landlord";
      } else {
        return "client";
      }
    }
  };

  const onClickManagement = () => {
    const pathFromStorage = localStorage.getItem("managementPath");
    localStorage.setItem("portalOrManagement", "management");
    trackEvent(
      "landlord management click",
      pathFromStorage || "/management/tenants",
      "Navbar Link"
    );
    if (pathFromStorage) {
      window.location.assign(pathFromStorage);
    } else {
      window.location.assign("/management/tenants");
    }
  };

  document.getElementById("dropdown-menuHouse-id") &&
    document
      .getElementById("dropdown-menuHouse-id")
      .addEventListener("moveover", (e) => {
        let itemList = document.getElementsByClassName("houseIconContainer")[0];
        itemList.parentNode.style.color = "blue";
        itemList.parentNode.style.fill = "#035aa6";
      });

  document.getElementById("dropdown-menuLands-id") &&
    document
      .getElementById("dropdown-menuLands-id")
      .addEventListener("mouseenter", (e) => {
        let itemList = document.getElementsByClassName("landsIconContainer")[0];

        itemList.parentNode.style.color = "blue";
        itemList.parentNode.style.fill = "#035aa6";
      });

  return (
    <>
      <nav
        style={scroll > 0 ? { background: "rgba(192,192,192,0.9)" } : {}}
        className="navbar navbar-expand-lg navbar-light "
      >
        <NavLink
          onClick={() => {
            setManageOrPortal("");
            localStorage.setItem("portalOrManagement", "");
            trackEvent("home page click", "/", "Navbar Link");
          }}
          className="navbar-brand"
          to="/"
        >
          <div className="logoingContainer">
            <img
              style={{ objectFit: "cover" }}
              className="logo"
              src="/images/theLogo.jpg"
              alt="_"
            />
          </div>
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <div className="homeContainer">
                <NavLink
                  onClick={() => {
                    setManageOrPortal("");
                    localStorage.setItem("portalOrManagement", "");
                    trackEvent("home page click", "/", "Navbar Link");
                  }}
                  className="nav-link"
                  to="/"
                >
                  <span>{t("home")}</span>
                </NavLink>
              </div>
            </li>

            <li className="nav-item dropdown dropdownHouse">
              <a
                className="nav-link housesContainer"
                href="#"
                id="navbarDropdown1"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="houseIconContainer">
                  {t("houses")}
                  <FaCaretDown className="houseDropIcon" />
                </div>
              </a>
              <div
                style={{
                  width: "150px",
                  zIndex: "50",
                  padding: "0",
                  textAlign: "center",
                  marginTop: "-5px",
                }}
                className="dropdown-menu dropdown-menuHouse"
                id="dropdown-menuHouse-id"
                aria-labelledby="navbarDropdown"
              >
                <NavLink
                  onClick={() => {
                    localStorage.setItem("portalOrManagement", "");
                    setManageOrPortal("");
                    trackEvent(
                      "rent a house click",
                      "/rent-a-house",
                      "Navbar Link"
                    );
                  }}
                  className="dropdown-item first"
                  to="/rent-a-house"
                >
                  {t("for_rent")}
                </NavLink>

                <NavLink
                  onClick={() => {
                    setManageOrPortal("");
                    localStorage.setItem("portalOrManagement", "");
                    trackEvent(
                      "buy a house click",
                      "/buy-a-house",
                      "Navbar Link"
                    );
                  }}
                  className="dropdown-item first"
                  to="/buy-a-house"
                >
                  {t("for_sell")}
                </NavLink>
              </div>
            </li>

            <li className="nav-item dropdown dropdownLands">
              <a
                className="nav-link landsContainer"
                href="#"
                id="navbarDropdown2"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  className="landsIconContainer"
                >
                  <div>{t("lands")}</div>
                  <div>
                    <FaCaretDown />
                  </div>
                </div>
              </a>
              <div
                style={{
                  zIndex: "2",
                  padding: "0",
                  textAlign: "center",
                  marginTop: "-5px",
                }}
                className="dropdown-menu dropdown-menuLands"
                id="dropdown-menuLands-id"
                aria-labelledby="navbarDropdown"
              >
                <NavLink
                  onClick={() => {
                    setManageOrPortal("");
                    localStorage.setItem("portalOrManagement", "");
                    trackEvent(
                      "rent a land click",
                      "/rent-a-land",
                      "Navbar Link"
                    );
                  }}
                  className="dropdown-item first"
                  to="/rent-a-land"
                >
                  {t("for_rent")}
                </NavLink>
                <NavLink
                  onClick={() => {
                    localStorage.setItem("portalOrManagement", "");
                    setManageOrPortal("");
                    trackEvent(
                      "buy a land click",
                      "/buy-a-land",
                      "Navbar Link"
                    );
                  }}
                  className="dropdown-item first"
                  to="/buy-a-land"
                >
                  {t("for_sell")}
                </NavLink>
              </div>
            </li>
          </ul>

          {isLandlord() === "client" && getCurrentUser() && (
            <div className="portalContainerClient">
              <Button
                style={{ textTransform: "capitalize" }}
                variant="outlined"
                onClick={redirectToTenantPortal}
                className="nav-link btn tenantBtnPortal"
                sx={{ fontSize: "18px" }}
              >
                <span style={{ marginRight: "15px" }}>
                  <FontAwesomeIcon
                    style={{
                      fontSize: "12px",
                    }}
                    icon={faPersonShelter}
                  />
                </span>
                {capitalize(t("tenant_portal"))}
              </Button>
            </div>
          )}

          {isLandlord() === "landlord" && (
            <div
              className={
                manageOrPortal === "portal"
                  ? "portalContainer"
                  : "myPortalContainer"
              }
            >
              <Button
                style={{ textTransform: "none" }}
                variant="outlined"
                onClick={redirectTo}
                className="nav-link btn"
              >
                <span style={{ marginRight: "5px" }}>
                  <FontAwesomeIcon
                    style={{
                      font: "normal normal bold 18px/25px Merriweather",
                    }}
                    icon={faPeopleRoof}
                  />
                </span>
                {t("Property Management")}
              </Button>
            </div>
          )}

          {getCurrentUser() && (
            <div
              className={
                currentLanguageCode === "en"
                  ? "navProfileContainerName"
                  : "navProfileContainerName1"
              }
            >
              <NavLink
                onClick={() => {
                  localStorage.setItem("portalOrManagement", "");
                  setManageOrPortal("");
                  trackEvent("profile link click", "/profile", "Navbar Link");
                }}
                className="nav-link"
                to="/profile"
              >
                <div className="navImgCon">
                  {picPath &&
                    picPath.fileProfile &&
                    picPath.fileProfile.public_id ? (
                    <Avatar
                      sx={{ width: "100%", height: "100%" }}
                      alt="Remy Sharp"
                      src={picPath.fileProfile.url}
                    />
                  ) : picPath && picPath.fileProfile ? (
                    <>
                      <Avatar
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "lightgray",
                        }}
                        alt="Remy Sharp"
                        src={picPath.fileProfile}
                      />
                    </>
                  ) : (
                    <FaUser className="userIcon" />
                  )}
                </div>
                <div className="name">
                  {firstName && firstName.length > 10
                    ? firstName
                      ? capitalize(firstName.slice(0, 10)) + "..."
                      : ""
                    : firstName
                      ? capitalize(firstName)
                      : ""}
                </div>
              </NavLink>
            </div>
          )}

          {!getCurrentUser() && (
            <div
              className={
                currentLanguageCode === "en"
                  ? "navProfileContainer"
                  : "navProfileContainer1"
              }
            >
              <Link
                className="nav-link"
                onClick={() =>
                  trackEvent("Sign up click", "/signUp", "Navbar Link")
                }
                to="/signUp"
              >
                <button className="btn">
                  <span>
                    <SignUpIcon className="signUpIcon" />
                    {t("sign_up")}
                  </span>
                </button>
              </Link>
            </div>
          )}

          {getCurrentUser() && (
            <div className="logoutContainer">
              <NavLink
                onClick={() =>
                  trackEvent("logout click", "/logout", "Navbar Link")
                }
                className="nav-link"
                to="/logout"
              >
                <span>
                  <FaSignOutAlt className="logoutIcon" />
                  {t("logout")}
                </span>
              </NavLink>
            </div>
          )}

          {!getCurrentUser() && (
            <div className="logoutContainer">
              <Link
                onClick={() =>
                  trackEvent("login click", "/login", "Navbar Link")
                }
                className="nav-link"
                to="/login"
              >
                <span className="loginSpan">
                  <Login className="loginIcon" />
                  {t("login")}
                </span>
              </Link>
            </div>
          )}

          <div className="globeContainer dropdown">
            <Globe color="#1A1110" />
          </div>
        </div>
      </nav>

      <div style={{ height: "58px" }}></div>
      {error && (
        <div className="pleaseCheckNetwork">
          Opp!!!!, Please check your network
        </div>
      )}
    </>
  );
};

export default Navbar;
