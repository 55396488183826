import React, { useEffect, useState, useContext } from "react";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

import EditButton from "../components/profile/EditButton";
import ProfileImage from "../components/profile/ProfileImage";
import Input from "../components/profile/Input";
import ProfileTextarea from "../components/profile/ProfileTextarea";
import DeleteButton from "../components/profile/DeleteButton";
import ModalProfile from "../components/profile/ModalProfile";
import ProfileSpinner from "../components/profile/ProfileSpinner";
// import LottieLoader from '../components/contactCaptivator/LottieLoader'
import ContactInput from "../components/profile/ContactInput";
import UpdateSpinner from "../components/profile/UpdateSpinner";
import SaveContainer from "../components/profile/SaveContainer";
import SaveButton from "../components/profile/SaveButton";
import DescriptionContainer from "../components/profile/DescriptionContainer";
import AlertCustomise from "../components/profile/AlertCustomise";
import StateZipcode from "../components/profile/StateZipcode";

import logger from "../Services/loggerService";
import { getCurrentUser } from "../Services/authService";
import {
  getUserById,
  getVericationForAUser,
  updateLandlordsPaymentMethod,
} from "../Services/registerService";
import { updateNameAndContact } from "../Services/registerService";
import { updateAddress } from "../Services/registerService";
import { updateDescription } from "../Services/registerService";
import { logout } from "../Services/authService";
import { deleteUser } from "../Services/registerService";
import { ProfileImages } from "../Services/profileService";
import Chat from "./../iconComponent/Chat";
import UnreadMsgsContext from "./../unreadmessages/context";
import {
  uploadMultipleFileToCloudinary,
  uploadSingleFileToCloudinary,
} from "./../Utils/imageUploadToCloudinary";
import { getCloudinarySignature } from "../Services/cloudinaryService";
import { registerUserForVerification } from "../Services/registerService";

import "../components/profile/profile.css";
import { trackEvent } from "./../Utils/useGAEventTracker";
import Meta1 from "../Utils/Meta1";
import { Alert, Button } from "@mui/material";
import VerifyAccount from "../components/profile/VerifyAccount";

const ProfileScreen = ({ history }) => {
  const { t } = useTranslation();

  //constants for Names and contact Edit
  const [nameAlert, setNameAlert] = useState(false);
  const [editNameInfo, setEditNameInfo] = useState(false);
  const [userss_id, setUser_id] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [passport, setPassport] = useState("");

  //constants for Address
  const [addressAlert, setAddressAlert] = useState(false);
  const [editAddressInfo, setEditAddressInfo] = useState(false);
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [errorsContact, setErrorsContact] = useState("");
  const [loadingContact, setLoadingContact] = useState(false);
  const [errorsAddress, setErrorsAddress] = useState("");
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [profession, setProfession] = useState("");

  //constants for Description
  const [descriptionAlert, setDescriptionAlert] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [errorsDescription, setErrorsDescription] = useState("");
  const [loadingDescription, setLoadingDescription] = useState(false);

  // constant for uploading pictures
  const [fileNames, setFileNames] = useState("");
  const [errorPicture, setErrorPicture] = useState("");
  const [picturePath, setPicturePath] = useState("");
  const [pic, setPic] = useState("");
  const [uploadPicLoading, setUploadPicLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [myImageData, setMyImageData] = useState({});

  const [langType, setLangType] = useState("");
  const [open, setOpen] = useState(false);

  const [idCardNumVer, setIdCardNumVer] = useState("");
  const [idCardImageVer, setIdCardImageVer] = useState([]);
  const [loadOtherPhoto, setLoadOtherPhoto] = useState(false);
  const [otherPhotoError, setOtherPhotoError] = useState("");
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [subscribeError, setSubscribeError] = useState("");
  const [usering, setUsering] = useState({});

  const [verificationData, setVerificationData] = useState({});

  // const numberOfUnreadMsgs = useContext(UnreadMsgsContext)
  const { unreadMsgs } = useContext(UnreadMsgsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserCompleteData();
    localStorage.setItem("portalOrManagement", "");
  }, []);

  // function to get all user complete data from database
  const getUserCompleteData = async () => {
    try {
      setLangType(cookies.get("i18next") || "en");
      const user_id = getCurrentUser() && getCurrentUser().id;

      if (user_id) {
        setPageLoading(true);
        const { data: vering } = await getVericationForAUser(user_id);
        setVerificationData(vering);
        setUser_id(user_id);

        const { data: user } = await getUserById(user_id);
        setUsering(user);
        const { data: imagesData } = await getCloudinarySignature();
        setMyImageData(imagesData);
        setFirstName(capitalize(user.first_name));
        setLastName(capitalize(user.last_name));
        setMiddleName(capitalize(user.middle_name));
        setEmail(user.email);

        if (user.contact) {
          setContact(user.contact);
        }

        if (user.passport) {
          setPassport(user.passport);
        }
        if (user.profession) {
          setProfession(user.profession);
        }
        if (user.street_address) {
          setStreetAddress(user.street_address);
        }

        if (user.city) {
          setCity(user.city);
        }
        if (user.states) {
          setState(user.states);
        }

        if (user.zip_code) {
          setZipCode(user.zip_code);
        }
        if (user.country) {
          setCountry(user.country);
        }

        if (user.descriptions) {
          setDescription(user.descriptions);
        }

        setPic(user.picture);
        setPageLoading(false);
      }
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        history.push("/notFound");
        logger.log("the error in get Users Profile", ex.response.data);
      }
    }
  };

  // function use to capitalise first letters
  const capitalize = (s) => {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  };

  // Name and contact submit
  const onNameAndContactSubmit = async (e) => {
    e.preventDefault();
    trackEvent(
      `${getCurrentUser() && getCurrentUser().id} edit name and contact`
    );
    const nameContact = {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      email,
      contact: contact ? countryCode + contact : "",
      passport,
      profession: profession ? profession : "",
    };

    trackEvent(
      `${getCurrentUser() && getCurrentUser().id} edit name and contact`,
      `to ${nameContact.first_name} ${nameContact.last_name}`,
      "name and contact edit btn profile"
    );
    try {
      setLoadingContact(true);
      setErrorsContact("");
      const { data: contactAndName } = await updateNameAndContact(
        userss_id,
        nameContact
      );
      setNameAlert(true);

      setFirstName(contactAndName.first_name);
      setLastName(contactAndName.last_name);
      setMiddleName(contactAndName.middle_name);
      setEmail(contactAndName.email);
      setContact(contactAndName.contact);
      setPassport(contactAndName.passport);

      setErrorsContact("");
      setLoadingContact(false);
      setEditNameInfo(false);
      setInterval(() => {
        setNameAlert(false);
      }, 7000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrorsContact(ex.response.data);
        setErrorsAddress("");
        setErrorsDescription("");
        setLoadingContact(false);
      }
    }
  };

  //submit Users Address to backend
  const onAddressSubmit = async (e) => {
    e.preventDefault();

    const addressData = {
      street_address: streetAddress,
      city,
      state,
      zip_code: ZipCode,
      country,
    };

    trackEvent(
      `${getCurrentUser() && getCurrentUser().id} edit address`,
      `to ${addressData.street_address}`,
      "edit address btn profile"
    );

    try {
      setLoadingAddress(true);
      const { data: updatedAddress } = await updateAddress(
        userss_id,
        addressData
      );
      setStreetAddress(updatedAddress.street_address);
      setCity(updatedAddress.city);
      setState(updatedAddress.states);
      setZipCode(updatedAddress.zip_code);
      setCountry(updatedAddress.country);
      setErrorsAddress("");
      setLoadingAddress(false);
      setEditAddressInfo(false);

      setAddressAlert(true);

      setInterval(() => {
        setAddressAlert(false);
      }, 7000);
      // alert(t('Account_Update'))
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrorsAddress(ex.response.data);
        setErrorsContact("");
        setErrorsDescription("");
        setLoadingAddress(false);
      }
    }
  };

  // function to update users description
  const onUpdateDescription = async (e) => {
    e.preventDefault();

    const descriptionData = {
      description,
    };

    trackEvent(
      `${getCurrentUser() && getCurrentUser().id} edit description`,
      `to ${descriptionData.description}`,
      "edit btn profile"
    );

    try {
      setLoadingDescription(true);
      const { data: updatedDescription } = await updateDescription(
        userss_id,
        descriptionData
      );
      setDescription(updatedDescription.descriptions);
      setErrorsDescription("");
      setLoadingDescription(false);
      setEditDescription(false);
      setDescriptionAlert(true);
      setInterval(() => {
        setDescriptionAlert(false);
      }, 7000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrorsDescription(ex.response.data);
        setErrorsContact("");
        setErrorsAddress("");
      }
    }
  };

  // function use to delete your profile account
  const onDeleteProfile = async () => {
    try {
      if (window.confirm(t("Are_you_sure_you_want_to_delete_this_Account"))) {
        trackEvent(
          `${getCurrentUser() && getCurrentUser().id} delete account`,
          `delete profile`,
          "delete profile btn"
        );
        await deleteUser(userss_id);
        logout();
        window.location = "/";
      }
    } catch (error) {
      logger.log(
        "error in onDeleteProfile function in ProfileScreen Component"
      );
    }
  };

  // function use to send file to cloudinary

  const handleFile = async (e) => {
    e.preventDefault();

    const size = e.currentTarget.files[0].size;
    const file = e.currentTarget.files[0];

    if (size > 8200683) {
      setErrorPicture(t("1.2Mb"));
    } else {
      setUploadPicLoading(true);

      let src = URL.createObjectURL(file);

      //convert to canvas
      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      let userImage = new Image();
      userImage.src = src;
      userImage.onload = () => {
        canvas.width = userImage.naturalWidth;
        canvas.height = userImage.naturalHeight;
        ctx.drawImage(userImage, 0, 0);

        //convert canvas to webP
        //     let webPImage = canvas.toDataURL('image/webp')

        canvas.toBlob(async (blob) => {
          const myImage = new File([blob], file.name, { type: blob.type });
          const result = await uploadSingleFileToCloudinary(
            myImage,
            myImageData
          );

          if (
            !result ||
            !result.url ||
            (result && result.path && result.path["error"])
          ) {
            setErrorPicture(
              "The was an error loading your image, please reload this image"
            );
            setUploadPicLoading(false);
            return;
          }
          setPicturePath(result);
          setUploadPicLoading(false);
        }, "image/webp");
      };
    }
  };


  //function to submit Image Path to the database
  const onSubmitPath = async (e) => {
    try {
      e.preventDefault();

      setLoadingImage(true);
      await ProfileImages(userss_id, { fileProfile: picturePath });
      trackEvent(
        `${getCurrentUser() && getCurrentUser().id} update image`,
        picturePath,
        "upload profile image btn"
      );
      setLoadingImage(false);

      setErrorPicture("");

      window.location = `/profile`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrorPicture(ex.response.data);
        setLoadingImage(false);
      }
    }
  };

  const editNameContactEdit = (data) => {
    if (data === "nameContact") {
      setEditNameInfo(true);
    }
  };

  const editAddressHandler = () => {
    setEditAddressInfo(true);
  };

  const editDescriptionHandler = () => {
    setEditDescription(true);
  };

  //function to upload multiple photos
  const onChangeOtherPhoto = async (e) => {
    e.preventDefault();
    setLoadOtherPhoto(true);
    const filesss = e.currentTarget.files;
    let myFile = [];
    for (const file of filesss) {
      if (file.size > 850683 && file.type.startsWith("video/")) {
        setOtherPhotoError(
          "large video file exist,Please upload a smaller video files not greater than 850683gb"
        );
        setLoadOtherPhoto(false);
      } else {
        const result = await uploadMultipleFileToCloudinary(file, myImageData);
        myFile.push(result);
      }
    }

    setIdCardImageVer([...idCardImageVer, ...myFile]);
    setLoadOtherPhoto(false);
  };

  const onSubscribe = async () => {
    try {
      if (!idCardNumVer)
        return setSubscribeError("Please, ID card number is required");
      if (idCardImageVer.length < 1)
        return setSubscribeError("Please, upload your ID card image");
      setLoadingVerify(true);
      const data = {
        id_number: idCardNumVer,
        id_screenshots: idCardImageVer,
      };

      const { data: dataVer } = await registerUserForVerification(data);
      setVerificationData(dataVer);

      setIdCardNumVer("");
      setIdCardImageVer([]);
      setLoadingVerify(false);
      setSubscribeError("");
      setOpen(false);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setSubscribeError(ex.response.data);
        setLoadingVerify(false);
      }
    }
  };

  if (pageLoading)
    return (
      <div className="profileScreenLoadingSpinner">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );
  return (
    <>
      {nameAlert && <AlertCustomise data={t("Account_Update")} />}
      {addressAlert && <AlertCustomise data={t("Account_Update")} />}
      {descriptionAlert && <AlertCustomise data={t("Account_Update")} />}
      <Meta1
        title={`${langType}.${t("meta_profile", {
          name: getCurrentUser().first_name,
        })}`}
        description="My Nawafrica Profile"
        link="/profile"
      />

      <ModalProfile
        path={picturePath}
        onSubmitPicture={onSubmitPath}
        onChange={handleFile}
        errors={errorPicture}
        fileName={fileNames}
        loading={uploadPicLoading}
        loadingImage={loadingImage}
      />

      <div style={{ overflow: "hidden" }} className="profileOuterCon">
        <div
          onClick={() =>
            getCurrentUser().post_rent_property === "I AM A PROPERTY OWNER"
              ? history.push("/portal/messages/message")
              : history.push("/portal/tenants/message")
          }
          className="recievedMessageCon1"
        >
          <div className="badge">
            {unreadMsgs.length > 99 ? "99+" : unreadMsgs.length}
          </div>
          <Chat className="headerSearchIcon" />
        </div>

        <div className="profileheaderTitleCon">
          <h5>{t("Profile")}</h5>
        </div>
        {usering.suspended && <div>
          <Alert style={{ width: "96%", margin: '10px 2%' }} severity="warning">
            This account has been suspended, please contact captivator technologies customer service for more information
          </Alert>
        </div>}

        {!editNameInfo && (
          <>
            <div className="nameContactTitle">
              <span className="name">
                <b>{t("NAMES_AND_CONTACT")}</b>{" "}
              </span>{" "}
              <span className="btnSpan">
                <EditButton onClick={editNameContactEdit} type="nameContact" />
              </span>{" "}
            </div>

            <div className="nameContactCon">
              <div className="nameContactInput">
                {errorsContact && (
                  <div className="alert alert-danger">{errorsContact}</div>
                )}
                {loadingContact && <UpdateSpinner />}
                <SaveContainer
                  labelValue={t("First_Name")}
                  data={firstName}
                  id="profileFN"
                />
                <SaveContainer
                  labelValue={t("Middle_Name")}
                  data={middleName}
                  placeholder={t("Middle_Name")}
                  id="profileMN"
                />
                <SaveContainer
                  labelValue={t("Last_Name")}
                  data={lastName}
                  id="profileMN"
                />
                <SaveContainer
                  labelValue={t("Email_Address")}
                  data={email}
                  id="profileMN"
                />

                <SaveContainer
                  labelValue={t("Contact")}
                  data={contact}
                  id="profileMN"
                  placeholder={t("Your_Phone_Number")}
                />

                <SaveContainer
                  labelValue={t("Passport/ID_Card_#")}
                  data={passport}
                  id="profileMN"
                  placeholder={t("Passport/ID_Card")}
                />

                {profession && (
                  <SaveContainer
                    labelValue={t("Profession")}
                    data={profession}
                    id="profileMN"
                    placeholder={t("eg_Carpenter")}
                  />
                )}
              </div>

              <div className="profileImageCon">
                <ProfileImage imageSrc={pic} />
              </div>
            </div>
          </>
        )}
        {editNameInfo && (
          <form onSubmit={onNameAndContactSubmit}>
            <div className="nameContactTitle">
              <span className="name">
                <b>{t("NAMES_AND_CONTACT")}</b>{" "}
              </span>{" "}
              <span className="btnSpan">
                <SaveButton />
              </span>{" "}
            </div>

            <div className="nameContactCon">
              <div className="nameContactInput">
                {errorsContact && (
                  <div className="alert alert-danger">{errorsContact}</div>
                )}
                {loadingContact && <UpdateSpinner />}
                {/* <SaveContainer /> */}
                <Input
                  labelValue={t("First_Name")}
                  placeholder={t("First_Name")}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setFirstName(value);
                  }}
                  id="profileFN"
                  fieldLabel="profileFN"
                  fieldInput="profileFNInput"
                  value={firstName}
                />
                <Input
                  labelValue={t("Middle_Name")}
                  placeholder={t("Middle_Name")}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setMiddleName(value);
                  }}
                  id="profileMN"
                  fieldLabel="profileMN"
                  fieldInput="profileMNInput"
                  value={middleName}
                />
                <Input
                  labelValue={t("Last_Name")}
                  placeholder={t("Last_Name")}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setLastName(value);
                  }}
                  id="profileLN"
                  fieldLabel="profileLN"
                  fieldInput="profileLNInput"
                  value={lastName}
                />
                <Input
                  labelValue={t("Email_Address")}
                  placeholder={t("Email")}
                  onChange={(e) => {
                    e.preventDefault();

                    const { value } = e.currentTarget;
                    setEmail(value);
                  }}
                  id="profileEmail"
                  fieldLabel="profileEmail"
                  fieldInput="profileEmailInput"
                  value={email}
                />

                <ContactInput
                  value={contact}
                  placeholder={t("Your_Phone_Number")}
                  onChangeSelect={(e) => {
                    e.preventDefault();
                    setCountryCode(e.target.value);
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setContact(value);
                  }}
                  countryCode={countryCode}
                />
                <Input
                  labelValue={t("Passport/ID_Card_#")}
                  placeholder={t("Passport/ID_Card")}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setPassport(value);
                  }}
                  id="profilePassport"
                  fieldLabel="profilePasspord"
                  fieldInput="profilePasspordInput"
                  value={passport}
                />
                <Input
                  labelValue={t("Profession")}
                  placeholder={t("eg_Carpenter")}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setProfession(value);
                  }}
                  id="profession"
                  fieldLabel="profession"
                  fieldInput="professionInput"
                  value={profession}
                />
              </div>

              <div className="profileImageCon">
                <ProfileImage imageSrc={pic} />
              </div>
            </div>
          </form>
        )}

        <hr className="nameContactHr" />
        {!editAddressInfo && (
          <>
            <div className="addressTitleCon">
              <span className="addressSpan">
                <b>{t("ADDRESS")}</b>{" "}
              </span>{" "}
              <span className="editBtn">
                <EditButton onClick={editAddressHandler} />
              </span>{" "}
            </div>

            <div className="addressInputCon">
              <div className="containInput">
                {errorsAddress && (
                  <div className="alert alert-danger">{errorsAddress}</div>
                )}
                {loadingAddress && <UpdateSpinner />}

                <SaveContainer
                  labelValue={t("Street_Address")}
                  data={streetAddress}
                  id="profileMN"
                  placeholder={t("Street_Address")}
                />

                <SaveContainer
                  labelValue={t("City")}
                  data={city}
                  id="profileMN"
                  placeholder={t("City")}
                />

                <div className="stateZipcodeCon">
                  <StateZipcode
                    labelValue={t("State")}
                    data={state}
                    id="profileMN"
                    placeholder={t("State")}
                  />

                  <StateZipcode
                    labelValue={t("ZipCode")}
                    data={ZipCode}
                    id="profileMN"
                    placeholder={t("ZipCode")}
                  />
                </div>

                <SaveContainer
                  labelValue={t("Country")}
                  data={country}
                  id="profileMN"
                  placeholder={t("Country")}
                />
              </div>

              <div className="emptyCon"></div>
            </div>
          </>
        )}

        {editAddressInfo && (
          <form onSubmit={onAddressSubmit}>
            <div className="addressTitleCon">
              <span className="addressSpan">
                <b>{t("ADDRESS")}</b>{" "}
              </span>{" "}
              <span className="editBtn">
                <SaveButton />
              </span>{" "}
            </div>

            <div className="addressInputCon">
              <div className="containInput">
                {errorsAddress && (
                  <div className="alert alert-danger">{errorsAddress}</div>
                )}
                {loadingAddress && <UpdateSpinner />}
                <Input
                  labelValue={t("Street_Address")}
                  placeholder={t("Street_Address")}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setStreetAddress(value);
                  }}
                  id="profileSA"
                  fieldLabel="profileSA"
                  fieldInput="profileSAInput"
                  value={streetAddress}
                />
                <Input
                  labelValue={t("City")}
                  placeholder={t("City")}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setCity(value);
                  }}
                  id="profileCity"
                  fieldLabel="profileCity"
                  fieldInput="profileCityInput"
                  value={city}
                />

                <div className="stateZipcodeCon">
                  <Input
                    labelValue={t("State")}
                    placeholder={t("State")}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      setState(value);
                    }}
                    id="profileState"
                    fieldLabel="profileState"
                    fieldInput="profileStateInput"
                    value={state}
                  />
                  <Input
                    labelValue={t("ZipCode")}
                    placeholder={t("ZipCode")}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      setZipCode(value);
                    }}
                    id="profileZipCode"
                    fieldLabel="profileZipCode"
                    fieldInput="profileZipCodeInput"
                    value={ZipCode}
                  />
                </div>

                <Input
                  labelValue={t("Country")}
                  placeholder={t("Country")}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setCountry(value);
                  }}
                  id="profileCountry"
                  fieldLabel="profileCountry"
                  fieldInput="profileCountryInput"
                  value={country}
                />
              </div>

              <div className="emptyCon"></div>
            </div>
          </form>
        )}

        <hr className="addressHr" />

        {!editDescription && (
          <>
            <div className="descriptionTitleCon">
              <span className="desSpan">
                <b>{t("DESCRIPTION")}</b>{" "}
              </span>
              <span className="editBtn">
                <EditButton onClick={editDescriptionHandler} />
              </span>
              <span className="paraSpan">{t("Say_a_few_words")}</span>
              {loadingDescription && <UpdateSpinner />}
            </div>
            {errorsDescription && (
              <div
                style={{
                  textAlign: "center",
                  width: "80%",
                  margin: "10px auto",
                }}
                className="alert alert-danger"
              >
                {errorsDescription}
              </div>
            )}

            <DescriptionContainer
              data={description}
              placeholder={t("please_say")}
            />
          </>
        )}
        {editDescription && (
          <form onSubmit={onUpdateDescription}>
            <div className="descriptionTitleCon">
              <span className="desSpan">
                <b>{t("DESCRIPTION")}</b>{" "}
              </span>
              <span className="editBtn">
                <SaveButton />
              </span>
              <span className="paraSpan">{t("Say_a_few_words")}</span>
              {loadingDescription && <UpdateSpinner />}
            </div>
            {errorsDescription && (
              <div
                style={{
                  textAlign: "center",
                  width: "80%",
                  margin: "10px auto",
                }}
                className="alert alert-danger"
              >
                {errorsDescription}
              </div>
            )}

            <ProfileTextarea
              onChange={(e) => {
                const { value } = e.currentTarget;
                setDescription(value);
              }}
              value={description}
            />
          </form>
        )}

        {getCurrentUser() &&
          getCurrentUser().post_rent_property === "I AM A PROPERTY OWNER" && (
            <hr className="descriptionHr" />
          )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
          }}
        >
          {usering.verified ? (
            <div>
              <Alert severity="success">Account is verified</Alert>
            </div>
          ) : verificationData.id ? (
            <div>
              <Alert severity="info">Your Verification is under Review!</Alert>
            </div>
          ) : getCurrentUser() &&
            getCurrentUser().post_rent_property === "I AM A PROPERTY OWNER" ? (
            <Button
              onClick={() => setOpen(true)}
              startIcon={<VerifiedOutlinedIcon />}
              variant="contained"
              style={{ background: "#035aa6" }}
            >
              Verify This Account
            </Button>
          ) : (
            ""
          )}
        </div>

        <hr className="descriptionHr" />

        <div className="deleteContainer">
          <b>{t("DELETE_ACCOUNT")}</b>
          <DeleteButton onClick={onDeleteProfile} />
        </div>
      </div>
      <VerifyAccount
        onChangeID={(e) => setIdCardNumVer(e.target.value)}
        open={open}
        onClose={() => setOpen(false)}
        onChangeImage={onChangeOtherPhoto}
        imgSrcOther={idCardImageVer}
        error={otherPhotoError}
        loading={loadOtherPhoto}
        onClickImage={(image) => {
          const remainingImages = idCardImageVer.filter(
            (img) => img.public_id !== image.public_id
          );
          setIdCardImageVer(remainingImages);
        }}
        onSubscribe={onSubscribe}
        subscribeError={subscribeError}
        loadingVerify={loadingVerify}
      />
      <ScrollToTop smooth />
    </>
  );
};

export default ProfileScreen;
