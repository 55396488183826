import React from "react";
import Spinner from "../Authentication/Spinner";
import UpdateSpinner from "./UpdateSpinner";
import { Image } from "cloudinary-react";
import { useTranslation } from "react-i18next";
import { setConfig, buildUrl } from "cloudinary-build-url";

import "./profile.css";

setConfig({
  cloudName: "dw9gomx53",
});

const ModalProfile = ({
  path,
  loadingImage,
  onChange,
  onSubmitPicture,
  errors,
  fileName,
  loading,
  nomember,
}) => {
  const { t } = useTranslation();
  // console.log("here is the path", path);
  const srcImage =
    path &&
    buildUrl(path.url, {
      transformations: {
        resize: {
          type: "scale",
          width: 600,
          height: 400,
        },
      },
    });

  return (
    <div
      className="modal fade"
      id="exampleModalCenterProfile"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          style={{ boxShadow: "2px 5px 7px black" }}
          className="modal-content profileModalContainer"
        >
          <form onSubmit={onSubmitPicture}>
            <div className="modal-header">
              <h5
                className="modal-title profileModalHeader"
                id="exampleModalLongTitle"
              >
                {nomember
                  ? "Upload Tenant Photo"
                  : t("Upload_Your_Profile_Picture")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="wrapper">
                  <div className="image">
                    {path && (
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          zIndex: "10",
                        }}
                        src={srcImage}
                      />
                      // <Image
                      //   style={{
                      //     height: "100%",
                      //     width: "100%",
                      //     objectFit: "cover",
                      //     zIndex: "10",
                      //   }}
                      //   cloudName="captivator"
                      //   publicId={path.public_id}
                      // />
                    )}
                  </div>
                  <div className="content">
                    <div className="icon">
                      <i className="fas fa-cloud-upload-alt"></i>
                    </div>
                    <div className="text">{t("No_file_chosen_yet!")}</div>
                  </div>
                  <div id="cancel-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  {fileName && <div className="file-name">{fileName}</div>}
                </div>

                <input
                  accept="image/*"
                  className="fileUpload"
                  name="file"
                  onChange={onChange}
                  type="file"
                  id="fileProfile"
                />
                <label id="custom-btn" htmlFor="fileProfile">
                  {t("Click_here_to_choose_a_file")}
                </label>
                {loading && (
                  <div className="profileImageUploadSpinner">
                    <Spinner />
                  </div>
                )}
                {errors && (
                  <div className="alert alert-danger profileImageErrorCon">
                    {errors}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                style={{ boxShadow: " 2px 5px 7px black " }}
                type="submit"
                className="btn profileImageModalBtn"
              >
                {t("Submit")}
              </button>
              {loadingImage && <UpdateSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalProfile;
