import React from 'react'
import SelectedItem1 from '../Home/SelectedItem1'

import { useTranslation } from "react-i18next";
import PriceInput from '../Home/PriceInput';

const SearchCityListings = ({
    itemsListingType,
    onChangeListingType,
    itemsCurrency,
    onChangePrice,
    onChangeCurrency
}) => {

    const { t } = useTranslation();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
                padding: "0 2%",
                margin: " 0 auto 20px auto",
            }}
        >

            <div style={{ flex: 1 }}>
                <SelectedItem1
                    placeholder={t("Listing_Type_(Any)")}
                    search={"listingType"}
                    items={itemsListingType}
                    onChange={onChangeListingType}
                    width="90%"
                />
            </div>
            <div style={{ flex: 1 }}>
                <PriceInput
                    items={itemsCurrency}
                    onChangePrice={onChangePrice}
                    onChangeCurrency={onChangeCurrency}
                    width="90%"
                />
            </div>

        </div>
    )
}

export default SearchCityListings
