import React from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { Image } from "cloudinary-react";

import { useTranslation } from "react-i18next";
import { getCurrentUser } from "../../Services/authService";
import TitlePriceLeaseSign from "../LeaseSignDetails/TitlePriceLeaseSign";
import MoreAboutListingLease from "../LeaseSignDetails/MoreAboutListingLease";
import ListingAnalytics from "./ListingAnalytics";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import CloseBtnDeal from "../LeaseSignDetails/CloseBtnDeal";
import SelectedDocumentModal from "./SelectedDocumentModal";

const ListingInfo1 = ({
  listingAnalysis,
  listing,
  tenant,
  monthsCover,
  dateLeaseStartAndExpiry,
  leaseMonthEnds = false,
  tenantSpace,
  availableSpace,
  onClickCloseDeal,
  errorCloseLease,
  loadingCloseLease,
}) => {
  const { t } = useTranslation();

  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const onClickImage = (src) => {
    setOpen(true);
    setSelectedDocument(src);
  }


  //- Fixed Lease. The Tenant shall be allowed to occupy the property starting and ending on an agreed date. If no renewal is made at the end of a Lease Term, the Tenant maybe requested by the Lease Manager/Owner to (Check One) :
  // -Renew The Fixed Lease Agreement.
  // -Switch to a month-by-month Agreement
  // -Must vacate the Premises

  // -Month-to-Month Lease. The Tenant shall be allowed to occupy the Premises on a month-to-month agreement, starting on An Agreed Month and ending upon notice 30 days from either Party to the other Party(Lease terms).

  // -Sold Lease. This property will be sold permanently to whoever that buys it.
  return (
    <div className="leaseSignDetailListingInfoContainer">
      <SelectedDocumentModal
        open={open}
        handleClose={() => setOpen(false)}
        selectedDocument={selectedDocument}
        onPrev={(select) => {
          const index = tenant.sign_lease_img.indexOf(select);
          if (index === 0) {
            setSelectedDocument(tenant.sign_lease_img[tenant.sign_lease_img.length - 1])
          } else {
            setSelectedDocument(tenant.sign_lease_img[index - 1])
          }
        }}
        onNext={(select) => {
          const index = tenant.sign_lease_img.indexOf(select);
          if (index === tenant.sign_lease_img.length - 1) {
            setSelectedDocument(tenant.sign_lease_img[0])
          } else {
            setSelectedDocument(tenant.sign_lease_img[index + 1
            ])
          }
        }}
      />
      <h5 className="title">{t("Listing_Info")}</h5>
      <hr style={{ margin: "5px auto" }} />
      <div style={{ display: "flex", flexDirection: "row", margin: "5px 5px" }}>
        <TitlePriceLeaseSign
          verified={getCurrentUser() && getCurrentUser().verified}
          title={_.toUpper(
            listing.title && listing.title.length > 25
              ? listing && listing.title.slice(0, 25) + "..."
              : listing && listing.title
          )}
          price={`${listing && listing.price} ${listing && listing.currency}`}
          rentOrSell={listing && listing.rent_or_sell}
        />
        {/* {getCurrentUser()&&getCurrentUser().post_rent_property === 'I AM A PROPERTY OWNER'&&<div style={{width:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}>
       <ListingAnalytics
       listingAnalysis={listingAnalysis}
       landlord={getCurrentUser()}
       listing={listing}
       />
      </div>} */}
      </div>
      <div className="infoDetailsContainerleaseSignDet">
        <div className="infoDetailsContainerleaseSignDetInnerCon">
          {listing && (
            <MoreAboutListingLease
              advancedPayment={`${listing.advance_price
                ? commaSeparateNumber(listing.advance_price)
                : ""
                } ${listing.currency}`}
            />
          )}

          <div className="landlordInfo">
            <div className="imageContainer">
              {getCurrentUser() &&
                getCurrentUser().picture &&
                getCurrentUser().picture.fileProfile.url ? (
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    getCurrentUser() &&
                    getCurrentUser().picture &&
                    getCurrentUser().picture.fileProfile.url
                  }
                />
              ) : (
                <FontAwesomeIcon
                  style={{ fontSize: "30px", color: "#035aa6" }}
                  icon={faUser}
                />
              )}
            </div>
            <div className="nameContainer">
              <h6>{capitalize(`${tenant.tenant_name}`)}</h6>
            </div>
          </div>
          <div className="leaseTypeLeasingSignDet">
            <span className="title">{t("Lease_Type")}: </span>
            <span className="monthToMonthLeasingSignDet">
              {listing.fixed_agreement
                ? t(`-Fixed_Lease`)
                : listing.month_to_month_agreement
                  ? t(`-Month-to-Month_Lease`)
                  : listing.sold_agreement
                    ? t("-Sold_Lease")
                    : ""}
            </span>
            {listing.fixed_agreement && (
              <div className="leasingEndLeasingDet">
                {listing.agreement_after_fixed_agreement
                  .renew_fixed_agreement ? (
                  <>
                    {" "}
                    <span style={{ marginRight: "5px" }}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>{" "}
                    {t("-renew_fixed_lease")}{" "}
                  </>
                ) : listing.agreement_after_fixed_agreement
                  .continue_with_month_to_month ? (
                  <>
                    <span style={{ marginRight: "5px" }}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    {t("-May_continue_to_lease")}
                  </>
                ) : listing.agreement_after_fixed_agreement.vacateP ? (
                  <>
                    <span style={{ marginRight: "5px" }}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    {t("-Must_vacate_the_Premises")}
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          {tenant?.sign_lease_img?.length > 0 &&
            <>
              <p style={{ color: "GrayText", textAlign: "center", fontWeight: "bold", fontSize: "12px" }}>Other documents attached</p>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                <div className="fileInputMultipleImg">
                  {tenant.sign_lease_img.map((src) => (
                    <div style={{ borderRadius: "5px" }} onClick={() => onClickImage(src)} key={src.public_id}>
                      {src.filePath && src.filePath.format === "pdf" ? (
                        <embed src={src.filePath.url} width="80px" height="80px" />
                      ) : (
                        <Image
                          style={{ border: "1px solid black", }}
                          crossOrigin="anonymous"
                          className="overlay-image animate__animated"
                          cloudName="dw9gomx53"
                          publicId={src.public_id}
                        />
                      )}
                    </div>
                  ))}
                </div>

              </div>
            </>
          }

          <hr className="mapSeparator" />
          <p style={{}}>
            <span style={{ font: "normal normal bold 14px/25px Merriweather" }}>
              {listing.listing_type} # :
            </span>
            <span
              style={{
                marginLeft: "5px",
                font: "normal normal normal 14px/25px Merriweather",
              }}
            >
              {tenantSpace.space_name ? tenantSpace.space_name : tenantSpace.space_number}
            </span>
          </p>
          <p style={{}}>
            <span style={{ font: "normal normal bold 14px/25px Merriweather" }}>
              Total # of {listing.listing_type}(s) :
            </span>
            <span
              style={{
                marginLeft: "5px",
                font: "normal normal normal 14px/25px Merriweather",
              }}
            >
              {availableSpace.length}
            </span>
          </p>
          <hr className="mapSeparator" />

          <div className="leaseStartingAndEndingBox">
            <div className="startDateContainer">
              <h5>{t("Lease_Starting_Date")} </h5>
              <p>{dateLeaseStartAndExpiry().startDate}</p>
            </div>
            <div
              className={
                leaseMonthEnds ? "startDateContainer1" : "startDateContainer"
              }
            >
              {leaseMonthEnds ? (
                <>
                  <Tooltip
                    title={`You have passed your lease ending date (${dateLeaseStartAndExpiry().endDate
                      }). If you wish to continue living in this property, 
              request your landlord to extend your lease end date, 
              so as to get full updates about your lease.`}
                  >
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <h5>{t("Lease_Ending_Date")} </h5>
                      <p>
                        {listing && listing.rent_or_sell === "Rental"
                          ? dateLeaseStartAndExpiry().endDate
                            ? dateLeaseStartAndExpiry().endDate
                            : t("Not_Specific_Date")
                          : t("owned")}
                      </p>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <h5>{t("Lease_Ending_Date")} </h5>
                  <p>
                    {listing && listing.rent_or_sell === "Rental"
                      ? dateLeaseStartAndExpiry().endDate
                        ? dateLeaseStartAndExpiry().endDate
                        : t("Not_Specific_Date")
                      : t("owned")}
                  </p>
                </>
              )}
            </div>

            <div className="startDateContainer">
              <h5>{t("Duration Spent")} </h5>
              <p>
                {Math.floor(monthsCover / 12) >= 1
                  ? `${Math.floor(monthsCover / 12)} ${monthsCover % 12}`
                  : monthsCover > 0
                    ? `${monthsCover} Months`
                    : "less than 1 Mth"}
                {/* {monthsCover > 0 ? `${monthsCover} Months` : 'less than 1 Mth'} */}
              </p>
            </div>
          </div>

          <hr className="mapSeparator" />

          <div className="ToTalPaidTotalOwedAmtBox">
            <div className="totalPaid">
              <h5>{t("TOTAL_AMOUNT_PAID")} </h5>
              <p>
                {tenant && tenant.total_amount_paid
                  ? commaSeparateNumber(tenant && tenant.total_amount_paid)
                  : ""}{" "}
                {listing && listing.currency}
              </p>
            </div>
            <div className="totalPaid">
              <h5>{t("TOTAL_AMOUNT_OWED")} </h5>
              <p>
                {Number(tenant && tenant.amount_owed) > 0
                  ? commaSeparateNumber(tenant && tenant.amount_owed)
                  : 0}{" "}
                {listing && listing.currency}
              </p>
            </div>
            <div className="totalPaid">
              {listing.rent_or_sell === "Rental" ? (
                <>
                  <h5>{t("TOTAL_MONTHS_PAID")}</h5>
                  <p>
                    {Math.floor(
                      Number(tenant && tenant.total_amount_paid) /
                      Number(listing && listing.price) /
                      12
                    ) > 0 &&
                      `${Math.floor(
                        Number(tenant && tenant.total_amount_paid) /
                        Number(listing && listing.price) /
                        12
                      )} Year(s)`}{" "}
                    {(
                      (Number(tenant && tenant.total_amount_paid) /
                        Number(listing && listing.price)) %
                      12
                    ).toFixed(2)}{" "}
                    Month(s)
                  </p>
                </>
              ) : (
                <>
                  <h5>{t("PAYMENT_STATUS")} :</h5>
                  <p>
                    {Number(tenant && tenant.total_amount_paid) >=
                      Number(listing.price)
                      ? "Complete"
                      : "Part"}
                  </p>
                </>
              )}
            </div>
          </div>

          <hr className="mapSeparator" />

          <div
            className="infoButtonContainer"
            style={{ width: "100%", padding: "20px 10px" }}
          >
            {loadingCloseLease ? (
              "....loading"
            ) : tenant?.tenant_close_deal && tenant?.landlord_close_deal ? (
              <div
                className="info info-danger"
                style={{
                  font: "normal normal normal 16px/25px Merriweather",
                  background: "red",
                  padding: "5px",
                  color: "white",
                  opacity: "0.7",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                This Agreement Have Been Closed In A Mutual Term By Landlord And
                Tenant
              </div>
            ) : (
              <Button
                sx={{
                  background: "rgba(255, 0, 0, 0.8)",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "20px",
                  font: "normal normal normal 14px/25px Merriweather",
                }}
                onClick={onClickCloseDeal}
                className={
                  // requestToClose
                  //   ? "btn closeDealButtonCon1"
                  "btn closeDealButtonCon"
                }
              >
                <span style={{ marginRight: "5px" }}>
                  <CloseBtnDeal />
                </span>
                Close Lease Agreement
              </Button>
            )}

            {errorCloseLease && (
              <p
                style={{ color: "red", textAlign: "center", marginTop: "10px" }}
              >
                {errorCloseLease}
              </p>
            )}
          </div>


        </div>
        <div className="pictureContaineLeaseSignDet">
          <img
            style={{ objectFit: "cover" }}
            src={listing?.main_photo?.path?.url}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingInfo1;
