import React from "react";
import { useTranslation } from "react-i18next";

import AddTenantInput from "./AddTenantInput";
import AddTenantSelect from "./AddTenantSelect";
import AddTenantFileInput from "./AddTenantFileInput";
import IdTypeInput from "./IdTypeInput";

import { FaWindowClose } from "react-icons/fa";

import "./manageTenants.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Alert, IconButton } from "@mui/material";
import { getCurrentUser } from "../../Services/authService";
import { getUserById } from "../../Services/registerService";

const AddTenantModal = ({
  valueFN,
  valueMN,
  valueLN,
  valueBN,
  valueAN,
  valueIDN,
  valueJT,
  valueCN,
  onChangeFN,
  onChangeMN,
  onChangeLN,
  onChangeBN,
  onChangeAN,
  onChangeIDT,
  onChangeJT,
  onChangeCN,
  onChangeUSL,
  onChangeIdType,
  onSubmitTenants,
  photoNameSignedLease,
  uploadLeaseSign,
  loadLeaseSign,
  errorUploadLeaseSign,
  errorInInput,
  postingTenant,
  listings,
  onChangeDate,
  valueStartDate,
  valueEndDate,
  onChangeDateEnd,
  availableSpaces,
  selectedListingSpaces,
  selectedListing,
  editing,
  onClickImage
}) => {
  const [user, setUser] = React.useState({})
  const { t } = useTranslation();

  React.useEffect(() => {
    getOwner()
  }, [])
  const getOwner = async () => {
    if (getCurrentUser().id) {
      const { data: us } = await getUserById(getCurrentUser().id)
      setUser(us);
    }
  };

  return (
    <div
      className="modal fade"
      id="exampleModalTenant"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div
          style={{ boxShadow: "2px 5px 7px black" }}
          className="modal-content addTenantModalOuterCon"
        >
          <div className="modal-header">
            <div className="addTenantTitle">{t("ADD_NEW_TENANT")}</div>
          </div>
          <div className="modal-body">
            <div
              data-dismiss="modal"
              aria-label="Close"
              className="closeIconContainerAddTenant"
            >
              <IconButton type="button">
                <FaWindowClose className="myCloseIcon" />
              </IconButton>
            </div>

            <div className="addTenantInfoTitle">{t("Tenant_info")}</div>
            <div className="underlineContainAddTenant">
              <hr />
            </div>
            <form onSubmit={onSubmitTenants}>
              <div className="row addTenantInputFieldsCon">
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantInput
                    onChange={onChangeFN}
                    title={t("First_Name*")}
                    id="FN"
                    value={valueFN}
                    placeholder={t("First_Name_ph")}
                  />
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantInput
                    onChange={onChangeMN}
                    title={t("Middle_Name*")}
                    id="MD"
                    value={valueMN}
                    placeholder={t("Middle_Name_ph")}
                  />
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantInput
                    onChange={onChangeLN}
                    title={t("Last_Name*")}
                    id="LN"
                    value={valueLN}
                    placeholder={t("Last_Name_ph")}
                  />
                </div>
                <div className="col-lg-6 col-md-6  col-sm-6 col-xs-6">
                  <IdTypeInput
                    onChange={onChangeIDT}
                    onChangeIdType={onChangeIdType}
                    title={t("Identification_Type#*")}
                    id="IDT"
                    value={valueIDN}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantInput
                    onChange={onChangeCN}
                    title={t("Contact_Number*")}
                    id="CN"
                    value={valueCN}
                    placeholder={t("Contact_Number_ph")}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantInput
                    onChange={onChangeJT}
                    title={t("Job_Title*")}
                    id="JT"
                    value={valueJT}
                    placeholder={t("Job_Title_ph")}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantSelect
                    onChange={onChangeBN}
                    title={`${t("listing")} *`}
                    id="BN"
                    value={valueBN}
                    placeholder={t("Building_Name_ph")}
                    items={listings}
                    disabled={editing}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantInput
                    onChange={onChangeAN}
                    title={t("Apt/Studio/Office#*")}
                    id="AN"
                    value={valueAN}
                    placeholder={t("Apt/Studio/Office_Number")}
                    chip={selectedListingSpaces?.length}
                    listing_spaces={selectedListingSpaces}
                    listing={selectedListing}
                    disabled={selectedListing.title ? false : true}
                  // disabled={editing}
                  />
                </div>

                {/* <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                  <AddTenantInput
                    onChange={onChangeHD}
                    title={t('House_Address*')}
                    id='HD'
                    value={valueHD}
                    placeholder={t('House_Address_ph')}
                  />
                </div> */}
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantInput
                    onChange={onChangeDate}
                    title={t("Lease start date*")}
                    id="IDT"
                    value={valueStartDate}
                    placeholder={t("Lease Start Date")}
                    type="date"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantInput
                    onChange={onChangeDateEnd}
                    title={t("Lease Ending date")}
                    id="IDT"
                    value={valueEndDate}
                    placeholder={t("Lease Ending Date")}
                    type="date"
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <AddTenantFileInput
                    onChange={onChangeUSL}
                    title={t("Upload_Signed_Lease")}
                    id="USL"
                    photoName={photoNameSignedLease}
                    uploadedImagesArray={uploadLeaseSign}
                    loading={loadLeaseSign}
                    error={errorUploadLeaseSign}
                    multiple={true}
                    onClickImage={onClickImage}
                  />
                </div>
                <div className="col-lg-12 addTenantSubmitBtnContainer">
                  {user.suspended ? (
                    <Alert style={{ width: "96%", margin: '10px 2%' }} severity="warning">
                      This account has been suspended, you can't add a new tenant
                    </Alert>
                  ) :
                    <button
                      style={{ boxShadow: "3px 1px 3px 0.5px black" }}
                      disabled={postingTenant}
                      type="submit"
                    >
                      <span style={{ marginRight: "5px" }}>
                        <FontAwesomeIcon icon={faSave} />
                      </span>
                      {t("Save")}
                    </button>}
                </div>
                {errorInInput && (
                  <div className="alert alert-danger addTenantErrorContainer">
                    {errorInInput}
                  </div>
                )}
                {postingTenant && (
                  <div className="addTenantPostingContainer">
                    {t(".......Adding")}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTenantModal;
