import React from "react";
import moment from "moment";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { setConfig, buildUrl } from "cloudinary-build-url";

import "../SearchListing/searchListing.css";
import "../../components/Home/styleCompo.css";

setConfig({
  cloudName: "dw9gomx53",
});

const SearchListingDisplayDetailSmall = ({
  listings,
  listingFromMap,
  onClickSelectedListing,
  placeholderNumberOfListing,
  onHoverListing,
}) => {
  const { t } = useTranslation();

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const calculateDate = (date) => {
    const postedDate = moment(parseInt(date));
    const nowDate = moment(Date.now());
    const diffInSec = nowDate.diff(postedDate, "seconds");
    const diffInMin = nowDate.diff(postedDate, "minutes");
    const diffInHour = nowDate.diff(postedDate, "hours");
    const diffInDay = nowDate.diff(postedDate, "days");
    const diffInMonth = nowDate.diff(postedDate, "months");
    const diffInYear = nowDate.diff(postedDate, "years");

    if (diffInSec < 60) return `${diffInSec} s ago`;
    if (diffInMin < 60) return `${diffInMin} m ago`;
    if (diffInHour < 24) return `${diffInHour} H ago`;
    if (diffInDay < 30) return `${diffInDay} D ago`;
    if (diffInMonth < 12) return `${diffInMonth} Mo ago`;

    return `${diffInYear} Y ago`;
  };

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  if (listings.length === 0)
    return (
      <div className="noAvailableListInfoContainerForSearchList">
        <div className="alert alert-danger">{placeholderNumberOfListing}</div>
      </div>
    );
  return (
    <div className="searchListingDisplayCon">
      <div className="row">
        <div
          style={{ height: "350px", width: "100%" }}
          id="carouselExampleSlidesOnlySmall"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            {listings.map((list, index) => (
              <li
                key={`${index}`}
                data-target="#carouselExampleSlidesOnlySmall"
                data-slide-to={`${index}`}
                className={index == 0 ? "active" : ""}
              ></li>
            ))}
          </ol>

          <div className="carousel-inner">
            {listings.map((list, index) => (
              <div
                key={`${index}`}
                style={{ width: "100%", height: "350px" }}
                className={
                  index === 0 ? "carousel-item active" : "carousel-item"
                }
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {list.map((listing) => {
                    const src = buildUrl(listing.main_photo.path.url, {
                      transformations: {
                        resize: {
                          type: "scale",
                          width: 900,
                          height: 500,
                        },
                      },
                    });
                    return (
                      <div
                        key={listing.id}
                        className={
                          listing === listingFromMap
                            ? "searchListingDisplayCardCon1 otherCityDisplayListing"
                            : "searchListingDisplayCardCon otherCityDisplayListing"
                        }
                      >
                        <div
                          onMouseEnter={() => onHoverListing(listing)}
                          // onMouseLeave={()=>onMouseLeave(null)}
                          id="mapSectionListContainer"
                          style={{ width: "100%" }}
                          className="card"
                        >
                          <img
                            crossOrigin="anonymous"
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                            }}
                            className="searchListingCarouselImages1"
                            src={src}
                            alt="First slide"
                          />
                          {/* <MoreCarousel
item={listing}
listingId={listing.id}
/> */}

                          <div
                            type="button"
                            onClick={() => onClickSelectedListing(listing)}
                            className="card-body cardBodyContainerForSearchList"
                          >
                            <div className="title_locationCon">
                              <h6 className="card-title card-title1">
                                <b>
                                  {capitalize(
                                    listing.title.length > 12
                                      ? listing.title.slice(0, 12) + ".."
                                      : listing.title
                                  )}
                                </b>
                              </h6>
                              <p className="card-text">
                                {t("numberListingInTown", {
                                  numberOfBedrooms:
                                    listing.listing_type !== "Land"
                                      ? listing.bedrooms
                                      : "",
                                  bedrooms:
                                    listing.listing_type !== "Land"
                                      ? t("Bedrooms")
                                      : "",
                                  listingType: capitalize(
                                    t(listing.listing_type)
                                  ),
                                  city: capitalize(listing.city),
                                })}
                              </p>
                            </div>

                            <div className="price_posted">
                              <h6 className="card-title card-title2">
                                <b>{`${commaSeparateNumber(listing.price)} ${listing.currency
                                  }`}</b>
                              </h6>
                              <p className="card-text">
                                Posted: {calculateDate(listing.dateposted)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          {listings.length > 1 && (
            <>
              <a
                style={{ zIndex: "2" }}
                className="carousel-control-prev"
                href="#carouselExampleSlidesOnlySmall"
                role="button"
                data-slide="prev"
              >
                <FaChevronLeft className="listingDetailsChevron" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                style={{ zIndex: "2" }}
                className="carousel-control-next"
                href="#carouselExampleSlidesOnlySmall"
                role="button"
                data-slide="next"
              >
                <FaChevronRight className="listingDetailsChevron" />
                <span className="sr-only">Next</span>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchListingDisplayDetailSmall;
