import React, { useEffect, useState } from "react";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";

import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import SubHeading from "../components/AboutUs/SubHeading";
import Paragraphs from "../components/AboutUs/Paragraphs";
import TopHeadingImage from "../components/AboutUs/TopHeadingImage";
import AllLinks from "../components/AboutUs/AllLinks";
import ShortVideo from "./../components/AboutUs/ShortVideo";
import VideotTitle from "../components/AboutUs/VideotTitle";
import WhoUse from "../components/AboutUs/WhoUse";
import LocationInMap from "../components/listingDetails/LocationInMap";
import ProfileSpinner from "../components/profile/ProfileSpinner";
import Meta from "../Utils/Meta";
import Meta1 from "../Utils/Meta1";
import DownloadApp from "../components/AboutUs/DownloadApp";

const AboutUs = () => {
  const [underConstruction, setUnderConstruction] = useState(false);
  const [key, setKey] = useState("");
  const [aboutActive, setAboutActive] = useState(true);
  const [impactActive, setImpactActive] = useState(false);
  const [crewActive, setCrewActive] = useState(false);

  useEffect(() => {
    setUnderConstruction(false);
    setAboutActive(true);
    window.scrollTo(0, 0);
    getKey();
  }, []);
  const getKey = async () => {
    try {
      const { data: keys } = await axios.get("/map");
      setKey({ googleMapsApiKey: keys, libraries: ["places"] });
    } catch (error) { }
  };

  const a = () => {
    setAboutActive(true);
    setImpactActive(false);
    setCrewActive(false);
  };

  const b = () => {
    setImpactActive(true);
    setAboutActive(false);
    setCrewActive(false);
  };

  const c = () => {
    setCrewActive(true);
    setImpactActive(false);
    setAboutActive(false);
  };

  if (!key)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );

  if (underConstruction)
    return (
      <>
        <Meta1
          title="Nawafrica | aboutUs"
          description="Learn more about Nawafrica"
          keywords="About Nawafrica"
          link="/about-us"
        />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <div style={{ width: "100%" }}>
      <Meta title="Nawafrica|aboutUs" />
      <div className="aboutUsOuterCon">
        <TopHeadingImage about={aboutActive} />
        <AllLinks
          aboutActive={aboutActive}
          impactActive={impactActive}
          crewActive={crewActive}
          a={a}
          b={b}
          c={c}
        />

        <DownloadApp />
        <ShortVideo />
        <VideotTitle />
        <div className="aboutSubHeadCon">
          <SubHeading title="Who uses nawafrica.com ?" />
        </div>
        <WhoUse />
      </div>
      <div className="aboutSubHeadCon">
        <SubHeading title="Where are we located ?" />
      </div>
      <div className=" aboutWhereParaCon">
        <Paragraphs>
          Why stay at home when you could visit Captivator technologies inc.?.
          Captivator technologies inc. is located in Bota, Limbe the south west
          region of Cameroon. The Map location of Captivator technologies inc.
          is shown below.
        </Paragraphs>
      </div>
      <div className="aboutUsMapCon">
        {key && (
          <LocationInMap
            keys={key}
            geoLocation={{
              lat: 4.0158698071650445,
              lng: 9.21244119464341,
            }}
            isAbout={true}
          />
        )}
      </div>
      <DownloadApp />
      <ScrollToTop smooth />
    </div>
  );
};

export default AboutUs;
