import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { GoogleLogin } from '@react-oauth/google'

import { registerWithGoogle } from '../Services/registerService'
import Spinner from '../components/Authentication/Spinner'
import Meta from './../Utils/Meta'
import { useTranslation } from 'react-i18next'
import { getLang } from '../Utils/getLang'

import '../components/Authentication/auth.css'
import logger from '../Services/loggerService'

const SignUpWithGoogle1 = ({ history }) => {
  const { t } = useTranslation()

  const [role, setRole] = useState('')
  const [publicKey, setPublicKey] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const myItemFromLocalStorage = localStorage.getItem('google-signup-role')
    if (!myItemFromLocalStorage) {
      history.push('/google_signUp1')
    } else {
      setRole(myItemFromLocalStorage)
    }
    getMyKey()
  }, [history])

  //function to get the public key when signing up with google
  const getMyKey = async () => {
    try {
      const { data } = await axios.get('/google_auth_public_key')
      setPublicKey(data)
    } catch (error) {
      logger.log(error)
    }
  }

  //function to submit google credentials to the backend
  const responseGoogle = async (response) => {
    try {
      // logger.log('here it is', response)
      console.log(response)
      const res = jwtDecode(response.credential)
      let myData = res
      myData.role = localStorage.getItem('google-signup-role')
      // logger.log(myData)
      // const myData = response.profileObj
      // myData.role = role
      setLoading(true)
      const { data } = await registerWithGoogle(myData)
      setLoading(false)
      localStorage.setItem('token', data)
      window.location = '/profile'
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        // logger.log('here is the error', ex.response.data)
        setError(ex.response.data)
        setLoading(false)
      }
    }
  }

  return (
    <div
      style={{
        marginTop: '40px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: 'url(/images/googleBackground.jpg)',
        width: '100%',
        height: '700px',
      }}
    >
      <Meta title={`${getLang()}.${t('Nawafrica | google_signup')}`} />

      {loading && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </div>
      )}

      <Link
        style={{ marginTop: '20px', marginLeft: '5px' }}
        to='/google_signUp1'
        className='btn btn-light'
      >
        <FaArrowLeft style={{ marginRight: '5px' }} />
        {t('go_back')}
      </Link>

      <p
        style={{
          color: 'red',
          font: 'normal normal bold 16px/28px Merriweather',
          textAlign: 'center',
          fontSize: '12px',
        }}
      >
        {error}
      </p>

      <div id='googleBtnContainer' className='googleBtnContainer'>
        <GoogleLogin
          onSuccess={responseGoogle}
          onError={() => {
            logger.log('Login Failed')
          }}
        />
        {/* <GoogleLogin
          onSuccess={(credentialResponse) => {
            logger.log(credentialResponse)
          }}
          onError={() => {
            logger.log('Login Failed')
          }}
        /> */}
        {/* {publicKey && (
          <GoogleLogin
            clientId={
              '605418182884-dkv63vodod8ete9a7ch33lop3iptv06q.apps.googleusercontent.com'
            }
            // clientSecret="XXu0ofrxNl1c0L1IrHG_8kgM"
            buttonText={t('Sign_Up')}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            render={(renderProps) => (
              <button
                className='googleLoginBtn'
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <span>
                  <img
                    style={{
                      height: '30px',
                      width: '30px',
                      borderRadius: '100%',
                    }}
                    src='/images/google.jpg'
                  />
                </span>
                <span>Google</span>
              </button>
            )}
          />
        )}{' '} */}
      </div>
      <div className='letMeGiveSomeSeparationOverAccountable'></div>
    </div>
  )
}

export default SignUpWithGoogle1
