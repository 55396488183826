import React from "react";
import { useTranslation } from "react-i18next";

import SearchMapListingDet from "./SearchMapListingDet";
import SearchListingDisplayDetails from "./SearchListingDisplayDetails";
import SearchListingDisplayDetailSmall from "./SearchListingDisplayDetailSmall";
import SearchCityListings from "./SearchCityListings";

const OtherListingInCity = ({
  listings,
  listingInCitySmall,
  listingInCityDisplay,
  onChangePrice,
  onMapLoad,
  keys,
  theSelected,
  onHoverListing,
  listingFromMap,
  onClickSelectedListing,
  onClickWindow,
  onPressImage,
  onClickSelectedListingImage,
  itemsListingType,
  onChangeListingType,
  totalCount = 0,
  listingCity,
  listingCountry
}) => {
  const { t } = useTranslation();


  return (
    <>
      <div className="otherListTitle">
        <hr className="otherListSeparator" />
        <a
          data-toggle="collapse"
          href="#collapseExampleOtherList"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExampleOtherList"
          style={{ textDecoration: "none" }}
        >
          <h3>
            {t("other_listing_in_city_title", {
              number_of_listings: totalCount,
              s: totalCount > 1 ? "s" : "",
              city: listingCity,
              country: listingCountry,
            })}
          </h3>
        </a>

      </div>
      <div className="collapse" id="collapseExampleOtherList">
        <SearchCityListings
          onChangePrice={onChangePrice}
          itemsListingType={itemsListingType}
          onChangeListingType={onChangeListingType}
        />
        {
          totalCount === 0 ? (
            <div
              style={{
                padding: "20px",
                background: "#d3d3d3",
                textAlign: "center",
                margin: "20px",
              }}
            >

              <p>
                {t("No more listing found in", {
                  city: listingCity,
                  country: listingCountry,
                })}
              </p>
            </div>
          ) : (
            <>
              <div className="otherListMapListContainer">
                <div className="otherListMapContainer">
                  <SearchMapListingDet
                    listings={listings}
                    onMapLoad={onMapLoad}
                    keys={keys}
                    theSelected={theSelected}
                    onClickWindow={onClickWindow}
                    onPressImage={onPressImage}
                  />
                </div>

                <hr className="otherListSeparator" />

                <div
                  style={{
                    background: "#d3d3d3",
                    paddingTop: "10px",
                  }}
                  className="otherListDisplayList"
                >
                  <SearchListingDisplayDetails
                    listings={listingInCityDisplay}
                    listingFromMap={listingFromMap}
                    onClickSelectedListing={onClickSelectedListing}
                    onHoverListing={onHoverListing}
                    theSelected={theSelected}
                    onClickSelectedListingImage={onClickSelectedListingImage}
                  />
                </div>

                <div
                  style={{ background: "#d3d3d3", paddingTop: "20px" }}
                  className="otherListDisplayListSmall"
                >
                  <SearchListingDisplayDetailSmall
                    listings={listingInCitySmall}
                    listingFromMap={listingFromMap}
                    onClickSelectedListing={onClickSelectedListing}
                    onHoverListing={onHoverListing}
                    onClickSelectedListingImage={onClickSelectedListingImage}
                  />
                </div>
              </div>
            </>
          )
        }

      </div>
    </>
  );
};

export default OtherListingInCity;
